import { t } from "i18next";
import React, { useEffect, useState } from "react";

import "./WithdrawsPage.scss";
import { transactionsType } from "common/types/AdminInfo/transactionsType/transactionsType";
import { AdminPanel } from "services";
import { TransationList } from "./UI";

const WithdrawsPage = () => {
  const [selectOpen, setSelectOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const handleMoveSelect = () => {
   setSelectOpen((prev) => !prev);
  };
  const [selectedType, setSelectedType] = useState("Deposit");
  const [selectedTypePrev, setSelectedTypePrev] = useState(selectedType);
  const [transactionList, setTransactionList] = useState<transactionsType[]>();
 
  function setTransactionWithdrawalsInfo() {
   setLoading(true);
   AdminPanel.withdrawals(
    `?user-dashboard/transactions?limit=100&page=1&type=${selectedType}&status=`
   )
    .then((res) => {
     setTransactionList(res.data);
    })
    .catch((error) => {
     console.error(error);
    })
    .finally(() => {
     setLoading(false);
    });
  }
  function setTransactionDepositInfo() {
   setLoading(true);
   AdminPanel.transactions(
    `?user-dashboard/transactions?limit=100&page=1&type=${selectedType}&status=`
   )
    .then((res) => {
     setTransactionList(res.data);
    })
    .catch((error) => {
     console.error(error);
    })
    .finally(() => {
     setLoading(false);
    });
  }
  const handleShow = () => {
   setSelectedType(selectedTypePrev);
   if (selectedTypePrev === "Deposit") {
    setTransactionDepositInfo();
   } else {
    setTransactionWithdrawalsInfo();
   }
  };
  useEffect(() => {
   handleShow();
  }, []);
  return (
    <div
   className="u-i-e-p-p-content-bc u-i-common-content"
   data-scroll-lock-scrollable=""
  >
   <div className="componentFilterWrapper-bc">
    <div
     className={`componentFilterLabel-bc ${selectOpen ? "active" : ""}`}
     onClick={handleMoveSelect}
    >
     <i className="componentFilterLabel-filter-i-bc bc-i-filter" />
     <div className="componentFilterLabel-filter-bc">
      <p className="ellipsis">Filter</p>
     </div>
     <i className="componentFilterChevron-bc bc-i-small-arrow-down" />
    </div>
    <div className="componentFilterBody-bc">
     <div className="componentFilterElsWrapper-bc">
      <div className="filter-form-w-bc">
       <div className="u-i-p-control-item-holder-bc">
        <div className="form-control-bc select has-icon valid filled">
         <label className="form-control-label-bc inputs">
          <select
           className="form-control-select-bc active"
           name="type"
           value={selectedTypePrev}
           onChange={(e) => setSelectedTypePrev(e.target.value)}
          >
           {selectedTypePrev === "Deposit" ? (
            <>
             <option value={"Deposit"}>{t("admin.Deposit")}</option>
             <option value={"Withdrawal"}>{t("admin.Withdrawal")}</option>
            </>
           ) : (
            <>
             <option value={"Withdrawal"}>{t("admin.Withdrawal")}</option>
             <option value={"Deposit"}>{t("admin.Deposit")}</option>
            </>
           )}
          </select>
          <i className="form-control-icon-bc bc-i-small-arrow-down" />
          <i className="form-control-input-stroke-bc" />
          <span className="form-control-title-bc ellipsis">
           {t("admin.TransactionType")}
          </span>
         </label>
        </div>
       </div>
       <div className="u-i-p-c-footer-bc">
        <button
         className="btn a-color "
         type="submit"
         title="Show"
         onClick={handleShow}
        >
         <span>{t("admin.Show")}</span>
        </button>
       </div>
      </div>
     </div>
    </div>
   </div>

   {!transactionList ? (
    <p className="empty-b-text-v-bc">
     {t("admin.Therearenodatafortheselectedtimeperiod")}
    </p>
   ) : (
    <>
     {loading ? null : (
      <TransationList
       selectedType={selectedType}
       transactionList={transactionList}
      />
     )}
    </>
   )}
  </div>
  );
};

export { WithdrawsPage };
