import React from "react";
import { Layout } from "../../components/layout";

import "./Aviator.scss";
import { useTypeSelector } from "hooks/useTypeSelector";

const Aviator = () => {
 const { showRedirectInfo } = useTypeSelector((data) => data.settingsReducer);
 return (
  <Layout
   containerStyles="is-home-page compact-footer hide-tab-navigation-footer-mobile "
   navHeader="aviator"
   loadStop={false}
   footerNav="casino"
  >
   <div
    className="layout-content-holder-bc"
    style={showRedirectInfo ? { marginTop: "60px" } : { marginTop: "0px" }}
   >
    <iframe
     className="iframe-full-page"
     title="https://pi.njoybingo.com/game.do?token=bea879be51aa8c2fd8f078ac6e5778fb_d2a6f3282965c9bfe2dca18d54dc26bd&pn=performads&game=SPRIBE-aviator&type=FREE&lang=en"
     src="https://pi.njoybingo.com/game.do?token=bea879be51aa8c2fd8f078ac6e5778fb_d2a6f3282965c9bfe2dca18d54dc26bd&pn=performads&game=SPRIBE-aviator&type=FREE&lang=en"
     allowFullScreen={true}
     allow="autoplay; microphone *"
    />
   </div>
  </Layout>
 );
};

export { Aviator };
