import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";

import enTranslation from "./i18n/locale/en/enTranslation.json";
import trTranslation from "./i18n/locale/ru/trTranslation.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    lng: "tr",
    fallbackLng: "tr",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      tr: {
        translation: trTranslation,
      },
      en: {
        translation: enTranslation,
      },
    },
  });

export default i18n;
