import { combineReducers } from "redux";
import modalsSlice from "./modalsReducer";
import settingsSlice from "./settingsReducer";
import favoritsSlice from "./favoritsReducer";
import accountInfoSlice from "./accountInfoReducer";

const rootReducer = combineReducers({
  accountInfoReducer: accountInfoSlice.reducer,
  settingsReducer: settingsSlice.reducer,
  modalsReducer: modalsSlice.reducer,
  favoritsReducer: favoritsSlice.reducer,
});

export { rootReducer };

export type RootState = ReturnType<typeof rootReducer>;
