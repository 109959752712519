import React from "react";
import { useTypeSelector } from "../../../../hooks/useTypeSelector";

import "./SmartPanelUnauth.scss";

interface ISmartPanelUnauth {
  setSettingsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSmartPanelVisibale: React.Dispatch<React.SetStateAction<boolean>>;
  setNotificationOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setFavoritsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setbetSlipHidden: React.Dispatch<React.SetStateAction<boolean>>;
}

const SmartPanelUnauth = ({
  setSettingsOpen,
  setSmartPanelVisibale,
  setNotificationOpen,
  setFavoritsOpen,
  setbetSlipHidden,
}: ISmartPanelUnauth) => {
  const favoritCasinoLocal = useTypeSelector(
    (data) => data.favoritsReducer.favoritCasinoLocal
  );
  const handleOpenSetting = () => {
    setSettingsOpen(true);
    setSmartPanelVisibale(false);
    setNotificationOpen(false);
  };
  const handleOpenNotifications = () => {
    setNotificationOpen(true);
    setSmartPanelVisibale(false);
    setSettingsOpen(false);
    setFavoritsOpen(false);
  };
  const handleOpenFavorits = () => {
    setFavoritsOpen(true);
    setSmartPanelVisibale(false);
    setSettingsOpen(false);
    setNotificationOpen(false);
  };
  const handleOpenBetslip = () => {
    setbetSlipHidden(true);
    setSmartPanelVisibale(false);
    setSettingsOpen(false);
    setNotificationOpen(false);
  };
  return (
    <div className="hdr-smart-panel-holder-bc">
      <button
        className="sp-button-bc "
        title="YENİLİKLER"
        data-component="Notifications"
        onClick={handleOpenNotifications}
      >
        <i
          id="smart-panel-notification-button-id"
          className="sp-button-icon-bc bc-i-notification"
        />
        <i className=" count-blink-even " data-badge="" />
      </button>
      <button
        className="sp-button-bc "
        title="FAVORİLER"
        data-component="FavoriteGames"
        onClick={handleOpenFavorits}
      >
        <i
          id="smart-panel-favorites-button-id"
          className="sp-button-icon-bc bc-i-star"
        />
        <i
          className={` ${
            favoritCasinoLocal.length > 0 ? "count-odd-animation" : ""
          } count-blink-odd `}
          data-badge={favoritCasinoLocal.length}
        />
      </button>
      <button
        className="sp-button-bc "
        title="Ayarlar"
        data-component="Settings"
        onClick={handleOpenSetting}
      >
        <i
          id="smart-panel-settings-button-id"
          className="sp-button-icon-bc bc-i-settings"
        />
        <span className="sp-button-content-text-bc" />
      </button>
      <button
        className="sp-button-bc "
        type="button"
        title="DESTEK İLE İLETİŞİME GEÇİN"
      >
        <i className="sp-button-icon-bc bc-i-live-chat" />
      </button>
      <button
        className="sp-button-bc "
        title="BAHİS KUPONU"
        data-component="Betslip"
        onClick={handleOpenBetslip}
      >
        <i className="sp-button-icon-bc bc-i-betslip" />
        <i className=" count-blink-even " data-badge="" />
      </button>
    </div>
  );
};

export { SmartPanelUnauth };
