import React, { useState } from "react";

import "./DataPickerYearSelect.scss";

interface IDataPickerYearSelect {
  years: any[];
  value: any;
  changeYear: any;
}

const DataPickerYearSelect = ({
  years,
  value,
  changeYear,
}: IDataPickerYearSelect) => {
  const [modalOpen, setModalOpen] = useState(false);
  const handleOpenModal = () => {
    setModalOpen((prev) => !prev);
  };
  const handleSelectYear = (item: any) => {
    changeYear(item);
  };
  return (
    <div
      className="form-control-bc select s-small filled"
      tabIndex={0}
      onClick={handleOpenModal}
    >
      <div className="form-control-label-bc form-control-select-bc inputs ">
        <span className="ellipsis">{value}</span>
        <i
          className={`form-control-icon-bc bc-i-small-arrow-${
            modalOpen ? "down" : "up"
          }`}
        />
        <i className="form-control-input-stroke-bc" />
      </div>
      {modalOpen ? (
        <div className="multi-select-label-bc" data-scroll-lock-scrollable="">
          {years.reverse().map((item) => {
            return (
              <label
                className={`checkbox-control-content-bc ${
                  value === item ? "active" : ""
                }`}
                data-option-value={item}
                key={item}
                onClick={() => handleSelectYear(item)}
              >
                <p
                  className="checkbox-control-text-bc"
                  style={{ pointerEvents: "none" }}
                >
                  {item}
                </p>
              </label>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export { DataPickerYearSelect };
