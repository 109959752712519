import { t } from "i18next";
import React from "react";
import { UseFormRegister, UseFormWatch } from "react-hook-form";
import { userFormType } from "../../RegisterForm";

import "./GenderInput.scss";

interface IGenderInput {
  register: UseFormRegister<userFormType>;
  watch: UseFormWatch<userFormType>;
  errorsRequest: any;
}

const GenderInput = ({ register, watch, errorsRequest }: IGenderInput) => {
  return (
    <div className="entrance-f-item-bc">
      <div className="form-control-bc select has-icon focused">
        <label className="form-control-label-bc inputs">
          <select
            className="form-control-select-bc active"
            {...register("gender")}
          >
            <option value="1">{t("admin.Male")}</option>
            <option value="0">{t("admin.Female")}</option>
          </select>
          <i className="form-control-icon-bc bc-i-small-arrow-down" />
          <i className="form-control-input-stroke-bc" />
          <span className="form-control-title-bc ellipsis">
            {t("admin.Gender")}
          </span>
        </label>
        {errorsRequest?.gender ? (
          <div className="form-control-message-holder-bc">
            <span className="form-control-message-bc">
              {errorsRequest?.gender ? errorsRequest?.gender : ""}
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export { GenderInput };
