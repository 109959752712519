import { t } from "i18next";
import React from "react";
import { MessageInput } from "./inpunts/MessageInput/MessageInput";
import { SubjectInput } from "./inpunts/SubjectInput/SubjectInput";

import "./NewPage.scss";

const NewPage = () => {
  return (
    <div
      className="u-i-e-p-p-content-bc u-i-common-content message-content"
      data-scroll-lock-scrollable=""
    >
      <div>
        <SubjectInput />
        <MessageInput />
        <div className="u-i-p-c-footer-bc">
          <button
            className="btn a-color "
            type="submit"
            title="Submit"
            disabled={true}
          >
            <span>{t("admin.Submit")}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export { NewPage };
