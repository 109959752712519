const promotionsTypeMock: { name: string; dataId: string }[] = [
  {
    name: "Hespi",
    dataId: "all",
  },
  // {
  //   name: "Spor Bonusları",
  //   dataId: "sport",
  // },
  // {
  //   name: "Casino Bonusları",
  //   dataId: "slots",
  // },
  // {
  //   name: "Özel Bonuslar",
  //   dataId: "belote",
  // },
  // {
  //   name: "Turnuvalar",
  //   dataId: "games",
  // },
];

export { promotionsTypeMock };
