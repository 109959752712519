import React, { useState } from "react";
import { PromoitonsTypeCart } from "./PromoitonsTypeCart/PromoitonsTypeCart";

import "./PromotionsType.scss";
import { promotionsTypeMock } from "./promotionsTypeMock";

const PromotionsType = () => {
  const [selectedCart, setSelectedCart] = useState("all");
  const handleSelectCart = (item: string) => {
    setSelectedCart(item);
  };
  return (
    <div
      className="horizontal-sl-list-container "
      data-scroll-lock-scrollable=""
    >
      <div className="horizontal-sl-list promotion-horizontal-sl-list-bc ">
        {promotionsTypeMock.map((item) => {
          return (
            <PromoitonsTypeCart
              key={item.dataId}
              typeInfo={item}
              selectedCart={selectedCart}
              handleSelectCart={handleSelectCart}
            />
          );
        })}
      </div>
    </div>
  );
};

export { PromotionsType };
