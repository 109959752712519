import { t } from "i18next";
import React, { useState } from "react";
import { Layout } from "../../components/layout";
import { TournamentProloader } from "../../components/tournaments/TournamentProloader/TournamentProloader";
import { TournamentsFilter } from "../../components/tournaments/TournamentsFilter/TournamentsFilter";

import "./TournamentsSports.scss";
import { useTypeSelector } from "hooks/useTypeSelector";

const TournamentsSports = () => {
 const [loading, setLoading] = useState(false);
 const { showRedirectInfo } = useTypeSelector((data) => data.settingsReducer);
 return (
  <Layout
   containerStyles="is-home-page casino-slots compact-footer "
   navHeader="tournamentsSports"
   footerNav=""
  >
   <div
    className="layout-content-holder-bc"
    style={showRedirectInfo ? { marginTop: "60px" } : { marginTop: "0px" }}
   >
    <TournamentsFilter setLoading={setLoading} />
    {loading ? (
     <TournamentProloader />
    ) : (
     <p className="empty-b-text-v-bc">
      {t("admin.Therearenotournamentswithselectedfilters")}.
     </p>
    )}
   </div>
  </Layout>
 );
};

export { TournamentsSports };
