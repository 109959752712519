import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { DataPickerMonthSelect } from "./components/DataPickerMonthSelect/DataPickerMonthSelect";
import { DataPickerYearSelect } from "./components/DataPickerYearSelect/DataPickerYearSelect";

import "./DatePickerCustom.scss";

const getYear = (date: any) => {
  return date.getFullYear();
};

// Вспомогательная функция для получения текущего месяца (от 0 до 11)
const getMonth = (date: any) => {
  return date.getMonth();
};

// Вспомогательная функция для генерации массива чисел в заданном диапазоне
const range = (start: any, end: any, step: any) => {
  const length = Math.floor((end - start) / step) + 1;
  return Array(length)
    .fill(0)
    .map((_, index) => start + index * step);
};

interface IDatePickerCustom {
  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
}

const DatePickerCustom = ({ startDate, setStartDate }: IDatePickerCustom) => {
  const years = range(1900, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleArrowMove = ({
    move,
    status,
  }: {
    move: () => void;
    status: boolean;
  }) => {
    status ? move() : null;
  };

  return (
    <div className="date-picker-container">
      <DatePicker
        className="form-control-input-bc custom-datepicker"
        dateFormat="dd.MM.yyyy"
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="left-arrow-custom-container">
              <i
                className="bc-i-small-arrow-left  arrow-custom"
                onClick={() =>
                  handleArrowMove({
                    move: decreaseMonth,
                    status: !prevMonthButtonDisabled,
                  })
                }
              />
            </div>
            <DataPickerMonthSelect
              months={months}
              value={months[getMonth(date)]}
              changeMonth={changeMonth}
            />
            <DataPickerYearSelect
              years={years}
              value={getYear(date)}
              changeYear={changeYear}
            />
            <div className="right-arrow-custom-container">
              <i
                className="bc-i-small-arrow-right arrow-custom"
                onClick={() =>
                  handleArrowMove({
                    move: increaseMonth,
                    status: !nextMonthButtonDisabled,
                  })
                }
              />
            </div>
          </div>
        )}
        selected={startDate}
        onChange={(date) => setStartDate(date)}
      />
      <i className="dropdownIcon-bc bc-i-datepicker" />
    </div>
  );
};

export { DatePickerCustom };
