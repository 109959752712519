import { t } from "i18next";
import React from "react";
import { useTypeSelector } from "../../../../../../../hooks/useTypeSelector";

import "./BuddyTransferPage.scss";

const BuddyTransferPage = () => {
  const firstname = useTypeSelector(
    (data) => data.accountInfoReducer.user.firstname
  );
  const lastname = useTypeSelector(
    (data) => data.accountInfoReducer.user.lastname
  );
  return (
    <div className="u-i-e-p-p-content-bc u-i-common-content">
      <div className="buddy-transfer">
        <form>
          <div className="buddy-transfer-bc">
            <div className="entrance-f-item-bc  ">
              <div className="entrance-f-error-message-bc">
                {t("admin.Therearenodatafortheselectedtimeperiod")}:
              </div>
            </div>
          </div>
          <div className="buddy-transfer-bc">
            <div className="radio-v-bc undefined">
              <div className="radio-control-bc ">
                <label className="radio-control-content-bc">
                  <input
                    type="radio"
                    className="radio-control-input-bc"
                    name="buddy_type"
                    step={0}
                    defaultValue="username"
                  />
                  <span className="radio-control-input-holder-bc" />
                  <p className="radio-control-text-bc">{t("admin.Username")}</p>
                </label>
              </div>
              <div className="radio-control-bc ">
                <label className="radio-control-content-bc">
                  <input
                    type="radio"
                    className="radio-control-input-bc"
                    name="buddy_type"
                    step={0}
                    defaultValue="friendName"
                  />
                  <span className="radio-control-input-holder-bc" />
                  <p className="radio-control-text-bc">
                    {t("admin.Friend Name")}
                  </p>
                </label>
              </div>
              <div className="radio-control-bc ">
                <label className="radio-control-content-bc">
                  <input
                    type="radio"
                    className="radio-control-input-bc"
                    name="buddy_type"
                    step={0}
                    defaultValue="userId"
                  />
                  <span className="radio-control-input-holder-bc" />
                  <p className="radio-control-text-bc">{t("admin.user id")}</p>
                </label>
              </div>
            </div>
          </div>
          <div className="buddy-transfer-item buddy-transfer-select">
            <p className="empty-b-text-v-bc">
              {t("admin.You have not friends")}
            </p>
          </div>
          <div className="buddy-transfer-item casino-game-tr-bc">
            <div className="casino-game-tr-form-block">
              <div className="casino-game-tr-from-to-bc">
                <div className="casino-game-tr-from-block-bc ellipsis">
                  <span className="casino-game-tr-label">
                    {t("admin.From")}
                  </span>
                  <span className="casino-game-tr-title ellipsis">
                    {firstname} {lastname}
                  </span>
                  <span className="casino-game-tr-price">0.00 ₺</span>
                </div>
                <i className="casino-game-tr-switcher-bc bc-i-arrow-to-right" />
                <div className="casino-game-tr-to-block-bc ellipsis">
                  <span className="casino-game-tr-label">{t("admin.To")}</span>
                  <span className="casino-game-tr-title ellipsis">******</span>
                </div>
              </div>
            </div>
          </div>
          <div className="buddy-transfer-item transfer-amount">
            <div className="form-control-bc default ">
              <label className="form-control-label-bc inputs">
                <input
                  type="text"
                  inputMode="decimal"
                  className="form-control-input-bc"
                  name="amount"
                  step="0.01"
                  defaultValue=""
                />
                <i className="form-control-input-stroke-bc" />
                <span className="form-control-title-bc ellipsis">
                  {t("admin.Amount")}
                </span>
              </label>
            </div>
            <button
              className="casino-game-tr-button btn "
              type="button"
              title="MAX"
            >
              <span>{t("admin.Max")}</span>
            </button>
          </div>
          <div className="buddy-transfer-item buddy-transfer-button">
            <button
              className="btn a-color "
              type="submit"
              title="Transfer"
              disabled={true}
            >
              <span>{t("admin.Transfer")}</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export { BuddyTransferPage };
