import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { CasinoIconGameType } from "../../common/types/Casino/CasinoIconGameType";

import "./CasinoList.scss";
import { GameItem } from "./gameItem/GameItem";

interface ICasinoList {
  casinoGames: CasinoIconGameType[];
  checkedGame: number;
  setCheckedGame: React.Dispatch<React.SetStateAction<number>>;
  loadMoreItem: () => Promise<void>;
  loadingGamesAdd: boolean;
  loadingAdd: boolean;
  liveCasino?: boolean;
}

const CasinoList = ({
  casinoGames,
  checkedGame,
  setCheckedGame,
  loadMoreItem,
  loadingGamesAdd,
  loadingAdd,
  liveCasino,
}: ICasinoList) => {
  const [hightList, setHightList] = useState(0);
  const handleCheckItem = (item: number) => {
    setCheckedGame(item);
  };
  const wrapperRef = useRef(null);
  const handleTapOutside = (event: MouseEvent | TouchEvent) => {
    if (
      wrapperRef.current &&
      !(wrapperRef.current as HTMLElement).contains(event.target as Node)
    ) {
      setCheckedGame(-1);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleTapOutside);
    document.addEventListener("touchstart", handleTapOutside);
    return () => {
      document.removeEventListener("mousedown", handleTapOutside);
      document.removeEventListener("touchstart", handleTapOutside);
    };
  }, []);
  const componentRef = useRef<HTMLDivElement>(null);
  window.onscroll = function () {
    if (window.pageYOffset >= hightList - 400) {
      loadMoreItem();
    }
  };

  useLayoutEffect(() => {
    const updateComponentHeight = () => {
      if (componentRef.current) {
        const { height } = componentRef.current.getBoundingClientRect();
        setHightList(height);
      }
    };

    updateComponentHeight();

    const observer = new ResizeObserver(updateComponentHeight);
    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);
  return (
    <div
      className="casino-category-types casino-category-types-full "
      id="casino_games_container"
    >
      <div className="infinite-scroll-component__outerdiv">
        <div
          className="infinite-scroll-component casino-category-games"
          style={{ height: "auto", overflow: "auto" }}
          ref={componentRef}
        >
          {loadingAdd
            ? Array.from({ length: 10 }).map((_, index) => (
                <div
                  key={index}
                  className="casino-game-item skeleton-loader-game-cube"
                />
              ))
            : casinoGames.map((item) => {
                return (
                  <GameItem
                    key={item.id}
                    item={item}
                    checkedGame={checkedGame}
                    wrapperRef={wrapperRef}
                    handleCheckItem={handleCheckItem}
                    liveCasino={liveCasino}
                  />
                );
              })}
          {loadingGamesAdd
            ? Array.from({ length: 10 }).map((_, index) => (
                <div
                  key={index}
                  className="casino-game-item skeleton-loader-game-cube"
                />
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export { CasinoList };
