import { t } from "i18next";
import React, { useState } from "react";

import "./SelectProvider.scss";

interface ISelectProvider {
  setProviderPopapOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setShowByParams: React.Dispatch<React.SetStateAction<boolean>>;
  showByParams: boolean;
  selectedProviders: number[];
  setSeacrhGames: React.Dispatch<React.SetStateAction<string>>;
  seacrhGames: string;
}

const SelectProvider = ({
  setProviderPopapOpen,
  setShowByParams,
  showByParams,
  selectedProviders,
  setSeacrhGames,
  seacrhGames,
}: ISelectProvider) => {
  const handleOpenProviderPopap = () => {
    setProviderPopapOpen(true);
  };
  const [inputActive, setInputActive] = useState(false);
  const handleMoveInput = () => {
    if (inputActive) {
      setSeacrhGames("");
    }
    setInputActive((prev) => !prev);
  };
  const handleInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSeacrhGames(e.target.value);
  };
  return (
    <div className="casino-select-provider-button-block-bc">
      <div className="casino-select-provider-button-bc">
        <button
          className={`btn s-small a-outline ${
            selectedProviders.length > 0 ? "a-white" : ""
          } `}
          type="button"
          title="Select provider"
          onClick={handleOpenProviderPopap}
        >
          <span>
          {t("casino.Selectprovider")}
            {" "}
            {selectedProviders.length > 0
              ? `(${selectedProviders.length})`
              : ""}
          </span>
        </button>
      </div>
      <button
        className={`btn show-allCategories ${showByParams ? "active" : ""} `}
        type="button"
        onClick={() => setShowByParams((prev) => !prev)}
      >
        <i className="bc-i-show-all-categories" />
      </button>

      <div className={`sport-search-bc ${inputActive ? "active" : ""} `}>
        <input
          type="text"
          className="ss-input-bc"
          placeholder="Search casino game"
          defaultValue=""
          onChange={handleInputValue}
          value={seacrhGames}
        />

        <div className="ss-icon-holder-bc" onClick={handleMoveInput}>
          <i
            className={`ss-icon-bc ${
              inputActive ? "bc-i-close-remove" : "bc-i-search"
            }`}
          />
        </div>
      </div>
    </div>
  );
};

export { SelectProvider };
