import React from "react";
import { Layout } from "../../components/layout";

import "./CanliTakvim.scss";

const CanliTakvim = () => {
  return (
    <Layout
      containerStyles="is-home-page skill-games-page games-custom-container"
      navHeader={"canlitakvim"}
    >
      <div className="custom-canlitakvim"></div>
    </Layout>
  );
};

export { CanliTakvim };
