import React from "react";
import "./MainHeader.scss";
import logo from "accets/images/png/logo.png";
import logoVeri from "accets/images/jpeg/logoVeri.jpeg";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useTypeSelector } from "hooks/useTypeSelector";
import { useDispatch } from "react-redux";
import { t } from "i18next";
import {
 setModalPopap,
 setModalLogin,
 setModalRegister,
} from "store/reducers/modalsReducer";

interface IMainHeader {
 setSmartPanelVisibale: React.Dispatch<React.SetStateAction<boolean>>;
 smartPanelVisibale: boolean;
}

const MainHeader = ({
 setSmartPanelVisibale,
 smartPanelVisibale,
}: IMainHeader) => {
 const dispatch = useDispatch();
 const [searchParams] = useSearchParams();

 const navigate = useNavigate();

 const balance = useTypeSelector(
  (data) => data.accountInfoReducer.balance.balance.amount
 );

 const { amount_bonus } = useTypeSelector(
  (data) => data.accountInfoReducer.balance.balance
 );

 const handleCloseWindow = () => {
  navigate("", {
   replace: true,
  });
 };

 const profile = searchParams.get("profile") || "";
 const handleOpenSmart = () => {
  !smartPanelVisibale && setSmartPanelVisibale(true);
 };
 const role = useTypeSelector((data) => data.accountInfoReducer.role);
 const handleOpenAuthForm = () => {
  dispatch(setModalPopap(true));
  dispatch(setModalLogin(true));
 };

 const language = useTypeSelector(
  (data) => data.settingsReducer.language.languageShort
 );

 const favoritCasinoLocal = useTypeSelector(
  (data) => data.favoritsReducer.favoritCasinoLocal
 );
 const favoritCasinoUser = useTypeSelector(
  (data) => data.favoritsReducer.favoritCasinoUser
 );

 const handleOpenRegisterForm = () => {
  dispatch(setModalPopap(true));
  dispatch(setModalRegister(true));
 };
 return (
  <div className="hdr-main-content-bc hdr-main-content-bc-custom">
   <div className="logo-container logo-container-custom">
    <Link className="logo" to={`/${language}/`}>
     <img className="hdr-logo-bc" src={logo} alt="" />
    </Link>
    <a
     target="_blank"
     href="https://licence-curacao-egaming.com/"
     rel="noreferrer"
    >
     <img
      src={logoVeri}
      alt=""
      width={20}
      height={20}
      style={{ top: "-2px", position: "relative" }}
     />
    </a>
   </div>
   {role === "unauth" ? (
    <div className="hdr-user-bc">
     <button
      className="btn s-small sign-in "
      type="button"
      title="Sign in"
      onClick={handleOpenAuthForm}
     >
      <span>{t("admin.Signin")}</span>
     </button>
     <button
      className="btn s-small register "
      type="button"
      title="Register"
      onClick={handleOpenRegisterForm}
     >
      <span>{t("admin.Register")}</span>
     </button>
    </div>
   ) : (
    <div className="hdr-user-bc">
     <div className="user-balance-dropdown">
      <div className="hdr-user-info-content-bc ">
       <span className="hdr-user-info-texts-bc ext-1 ellipsis">
        {balance + amount_bonus} ₺
       </span>
      </div>
     </div>
     <Link
      to={{
       search: "?profile=open",
      }}
     >
      <i className="hdr-user-avatar-icon-bc bc-i-user user-verified" />
     </Link>
    </div>
   )}

   {profile === "open" ? (
    <i
     className="hdr-user-close bc-i-close-remove"
     onClick={handleCloseWindow}
    />
   ) : (
    <>
     {role === "player" ? (
      <i
       className={`${
        favoritCasinoUser.length > 0 && !smartPanelVisibale
         ? "count-odd-animation"
         : ""
       } count-blink-even hdr-toggle-button-bc bc-i-vertical-toggle custom-icon`}
       data-badge={favoritCasinoUser.length}
       id="smart-panel-holder"
       onClick={handleOpenSmart}
      />
     ) : (
      <i
       className={`${
        favoritCasinoLocal.length > 0 && !smartPanelVisibale
         ? "count-odd-animation"
         : ""
       } count-blink-even hdr-toggle-button-bc bc-i-vertical-toggle custom-icon`}
       data-badge={favoritCasinoLocal.length}
       id="smart-panel-holder"
       onClick={handleOpenSmart}
      />
     )}
    </>
   )}
  </div>
 );
};

export { MainHeader };
