import React, { useState } from "react";

import "./JackpotTopBar.scss";

const JackpotTopBar = () => {
  const [categoryJacpotSelected, setCategoryJackpotSelected] = useState("EGT");
  const handleCategorySelect = (item: string) => {
    setCategoryJackpotSelected(item);
  };
  return (
    <div className="casinoJackpotTopBar-bc custom">
      <div className="casinoJackpotSlider-bc">
        <div className="casinoJackpotSliderWrapper-bc">
          <div className="casino-jackpot-slider-bc">
            <ul
              className="casinoJackpotSliderElContainer-bc"
              style={{ transform: "translateX(0%)" }}
            >
              <li
                onClick={() => handleCategorySelect("EGT")}
                className={`casinoJackpotSliderEl-bc bc-i-egt ${
                  categoryJacpotSelected === "EGT" ? "active" : ""
                }`}
                style={{ width: "50%" }}
              />
              <li
                onClick={() => handleCategorySelect("FGS")}
                className={`casinoJackpotSliderEl-bc bc-i-fgs ${
                  categoryJacpotSelected === "FGS" ? "active" : ""
                }`}
                style={{ width: "50%" }}
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export { JackpotTopBar };
