import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useTypeSelector } from "../../../../hooks/useTypeSelector";
import { GameItem } from "../../../casinoList/gameItem/GameItem";

import "./FavoritsModal.scss";

interface IFavoritsModal {
  setFavoritsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const FavoritsModal = ({ setFavoritsOpen }: IFavoritsModal) => {
  const [checkedGame, setCheckedGame] = useState(-1);
  const role = useTypeSelector((data) => data.accountInfoReducer.role);
  const handleCheckItem = (item: number) => {
    setCheckedGame(item);
  };
  const wrapperRef = useRef(null);
  const handleTapOutside = (event: MouseEvent | TouchEvent) => {
    if (
      wrapperRef.current &&
      !(wrapperRef.current as HTMLElement).contains(event.target as Node)
    ) {
      setCheckedGame(-1);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleTapOutside);
    document.addEventListener("touchstart", handleTapOutside);
    return () => {
      document.removeEventListener("mousedown", handleTapOutside);
      document.removeEventListener("touchstart", handleTapOutside);
    };
  }, []);
  const [favoriteType, setFavoriteType] = useState("Sport");
  const [sportType, setSportType] = useState("Competition");

  const handleCloseModal = () => {
    setFavoritsOpen(false);
  };

  const handleSelectFavorite = (item: string) => {
    setFavoriteType(item);
  };
  const handleSelectSport = (item: string) => {
    setSportType(item);
  };
  const favoritCasinoLocal = useTypeSelector(
    (data) => data.favoritsReducer.favoritCasinoLocal
  );
  const favoritCasinoUser = useTypeSelector(
    (data) => data.favoritsReducer.favoritCasinoUser
  );
  return (
    <div
      className="overlay-sliding-wrapper-bc  "
      style={{ transform: "translateY(0px)", opacity: 1 }}
    >
      <div
        className="overlay-sliding-w-c-content-slider-bc"
        data-scroll-lock-scrollable=""
      >
        <div className="back-nav-bc" onClick={handleCloseModal}>
          <i className="back-nav-icon-bc bc-i-round-arrow-left" />
          <span className="back-nav-title-bc ellipsis">
            {t("admin.Favorites")}
          </span>
        </div>
        <div className="smart-panel-tabs-bc">
          <div className="second-tabs-bc">
            <div
              className={`tab-bc selected-underline ${
                favoriteType === "Sport" ? "active" : ""
              }`}
              title=""
              onClick={() => handleSelectFavorite("Sport")}
            >
              <span>{t("navHeader.Sport")}</span>
              <span>(0)</span>
            </div>
            <div
              className={`tab-bc selected-underline ${
                favoriteType === "Casino" ? "active" : ""
              }`}
              title=""
              onClick={() => handleSelectFavorite("Casino")}
            >
              <span>{t("navHeader.Casino")}</span>
              {role === "player" ? (
                <span>({favoritCasinoUser.length})</span>
              ) : (
                <span>({favoritCasinoLocal.length })</span>
              )}
            </div>
          </div>
          <div className="sp-fav-body-bc">
            {favoriteType === "Sport" ? (
              <div className="second-tabs-bc">
                <div
                  className={`tab-bc selected-underline ${
                    sportType === "Competition" ? "active" : ""
                  }`}
                  title=""
                  onClick={() => handleSelectSport("Competition")}
                >
                  <span>{t("admin.Competition")}</span>
                  <span>(0)</span>
                </div>
                <div
                  className={`tab-bc selected-underline ${
                    sportType === "Match" ? "active" : ""
                  }`}
                  title=""
                  onClick={() => handleSelectSport("Match")}
                >
                  <span>{t("admin.Match")}</span>
                  <span>(0)</span>
                </div>
              </div>
            ) : null}
            {favoriteType === "Sport" ? (
              <p className="empty-b-text-v-bc">{t("admin.donthavefavorit")}.</p>
            ) : (
              <>
                {role === "palyer" ? (
                  <>
                    {favoritCasinoUser.length === 0 ? (
                      <p className="empty-b-text-v-bc">
                        {t("admin.donthavefavorit")}.
                      </p>
                    ) : null}
                  </>
                ) : (
                  <>
                    {favoritCasinoLocal.length === 0 ? (
                      <p className="empty-b-text-v-bc">
                        {t("admin.donthavefavorit")}.
                      </p>
                    ) : null}
                  </>
                )}

                <div>
                  <div className="bs-remove-all-bc bs-favorite-remove-bc">
                    <div className="bs-favorite-remove">
                      <span className="betslip-remove-all ellipsis">
                        Tümünü Kaldır
                      </span>
                    </div>
                  </div>
                  {role === "player" ? (
                    <div className="casino-category-games">
                      {favoritCasinoUser.map((item) => {
                        return (
                          <GameItem
                            key={item.id}
                            item={item}
                            handleCheckItem={handleCheckItem}
                            wrapperRef={wrapperRef}
                            checkedGame={checkedGame}
                          />
                        );
                      })}
                    </div>
                  ) : (
                    <div className="casino-category-games">
                      {favoritCasinoLocal.map((item) => {
                        return (
                          <GameItem
                            key={item.id}
                            item={item}
                            handleCheckItem={handleCheckItem}
                            wrapperRef={wrapperRef}
                            checkedGame={checkedGame}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { FavoritsModal };
