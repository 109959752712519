import React, { useEffect, useState } from "react";
import { Layout } from "../../components/layout";
import Helmet from "react-helmet";
import Foo from "../../test.d";

import "./Live.scss";
import { useTypeSelector } from "../../hooks/useTypeSelector";
import { CasinoInfo } from "services";

const Live = () => {
 const [loading, setLoading] = useState(true);
 const [linkPlayer, setLinkPlayer] = useState("");
 const bearerToken = localStorage.getItem("token");
 const role = useTypeSelector((data) => data.accountInfoReducer.role);
 const { showRedirectInfo } = useTypeSelector((data) => data.settingsReducer);
 const lang = useTypeSelector(
  (data) => data.settingsReducer.language.languageShort
 );

 async function getLinkPLayer() {
  try {
   const res = await CasinoInfo.sportbookStartSession(lang);
   setLinkPlayer(res.data);
   setLoading(false);
  } catch (e) {
   console.error(e);
   setLoading(false);
  }
 }
 useEffect(() => {
  if (bearerToken && role === "player") {
   getLinkPLayer();
  } else {
   setLoading(false);
  }
 }, []);
 return (
  <Layout
   containerStyles="is-home-page    "
   navHeader="Live"
   footerNav="none"
   footerNavType="casino"
  >
   <div
    className="layout-content-holder-bc"
    style={showRedirectInfo ? { marginTop: "60px" } : { marginTop: "0px" }}
   >
    {loading ? null : (
     <>
      {linkPlayer ? (
       <div>
        <iframe
         src={linkPlayer}
         width={"100%"}
         height={"100vh"}
         className="custom-iframe-container"
        />
       </div>
      ) : (
       <div>
        <iframe
         src={`https://spwaio.igpxl.com/${lang}/sport?serverUrl=https://apispwaio.igpxl.com`}
         width={"100%"}
         height={"100vh"}
         className="custom-iframe-container"
        />
       </div>
      )}
     </>
    )}
   </div>
  </Layout>
 );
};

export { Live };
