import React, { useState } from "react";
import { useDispatch } from "react-redux";

import "./RedirectInfo.scss";
import { useTypeSelector } from "hooks/useTypeSelector";
import { setRedirectInfo } from "store/reducers/settingsReducer";

const RedirectInfo = () => {
 const dispatch = useDispatch();
 const handleCloseInfo = () => {
  dispatch(setRedirectInfo(false));
 };
 const { showRedirectInfo } = useTypeSelector((data) => data.settingsReducer);
 const lang = useTypeSelector(
  (data) => data.settingsReducer.language.languageShort
 );
 const currentSite = window.location.host.replace(/^(www\.)/, "");
 const siteList =
  useTypeSelector(
   (data) =>
    data.settingsReducer.content.skinStyles?.filter(
     (item: any) => item.key === "url_available_domains_list"
    )[0].value
  ) || "";
 const notify_current_domain =
  useTypeSelector(
   (data) =>
    data.settingsReducer.content.skinStyles?.filter(
     (item: any) => item.key === "notify_current_domain"
    )[0].value
  ) || "";
 const text1 = notify_current_domain
  ? JSON.parse(notify_current_domain)[lang]?.text1
  : "";
 const text2 = notify_current_domain
  ? JSON.parse(notify_current_domain)[lang]?.text2
  : "";
 const text3 = notify_current_domain
  ? JSON.parse(notify_current_domain)[lang]?.text3
  : "";
 return showRedirectInfo ? (
  <div className="redirect_container">
   <a className="informative-widget-link custom-text-bg">
    <p className="custom-text">
     {lang === "tr" ? (
      <>
       {text1} {currentSite} {text2}{" "}
       <a
        href={siteList}
        target="_blank"
        rel="noopener noreferrer"
        style={{
         color: "#ffffffb3",
         marginLeft: "5px",
         marginRight: "5px",
        }}
       >
        {siteList.replace(/^https?:\/\//, "").replace(/\/$/, "")}
       </a>{" "}
       {text3}.
      </>
     ) : (
      <>
       {text1} {currentSite}.{" "}
       {text2}{" "}
       <a
        href={siteList}
        target="_blank"
        rel="noopener noreferrer"
        style={{
         color: "#ffffffb3",
         marginLeft: "5px",
         marginRight: "5px",
        }}
       >
        {siteList.replace(/^https?:\/\//, "").replace(/\/$/, "")}
       </a>
       .
      </>
     )}
    </p>
   </a>
   <i
    className="e-p-close-icon-bc bc-i-close-remove"
    onClick={handleCloseInfo}
   ></i>
  </div>
 ) : null;
};

export { RedirectInfo };
