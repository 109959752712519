import { watch } from "fs";
import { t } from "i18next";
import React, { useEffect, useState } from "react";

import "./AffiliateInput.scss";

const AffiliateInput = () => {
  const [inputValue, setInputValue] = useState("");
  const [inputFocus, setInputFocus] = useState(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleOnFocus = () => {
    setInputFocus(true);
  };

  const handleOnBlur = () => {
    if (inputValue.length === 0) {
      setInputFocus(false);
    }
  };

  return (
    <div className="entrance-f-item-bc">
      <div
        className={`form-control-bc default  ${inputFocus ? "focused" : ""}`}
      >
        <label className="form-control-label-bc inputs">
          <input
            type="text"
            className="form-control-input-bc"
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            onChange={handleChange}
          />
          <i className="form-control-input-stroke-bc" />
          <span className="form-control-title-bc ellipsis">{t("admin.Affiliatecode")}</span>
        </label>
      </div>
    </div>
  );
};

export { AffiliateInput };
