import React from "react";
import "./CenterPannelButton.scss";

interface ICenterPannelButton {
  centerButtonVisivaly: boolean
}

const CenterPannelButton = ({ centerButtonVisivaly }: ICenterPannelButton) => {

  const handleScrollTo = () => {
    const scrollStep = -window.scrollY / (500 / 15); // 500 - скорость прокрутки, можно изменить на нужную величину

    const scrollAnimation = () => {
      if (window.scrollY !== 0) {
        window.scrollBy(0, scrollStep);
        requestAnimationFrame(scrollAnimation);
      }
    };

    scrollAnimation();
  }
  
  return (
    <div className={`${centerButtonVisivaly ? "center-bottom-btn" : "center-bottom-btn nav-floating-btn-hide"}`}>
      <button
        className="btn nav-floating-btn bc-i-double-arrow-top custom-center-button"
        type="button"
        onClick={handleScrollTo}
      />
    </div>
  );
};

export { CenterPannelButton };
