import { t } from "i18next";
import React from "react";

import "./UnselectedGrop.scss";

interface IUnselectedGrop {
  setSelectedGroup: React.Dispatch<React.SetStateAction<string>>;
}

const UnselectedGrop = ({ setSelectedGroup }: IUnselectedGrop) => {
  const handleSelectGroup = (item: string) => {
    setSelectedGroup(item);
  };
  return (
    <>
      <div
        className="u-i-p-l-head-bc"
        onClick={() => handleSelectGroup("Balance Management")}
      >
        <i className="user-nav-icon bc-i-balance-management" />
        <span className="u-i-p-l-h-title-bc ellipsis">
          {t("admin.BalanceManagement")}
        </span>
        <i className="  count-blink-even " data-badge="" />
        <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-right" />
      </div>
      <div
        className="u-i-p-l-head-bc"
        onClick={() => handleSelectGroup("Bet Builder history")}
      >
        <i className="user-nav-icon bc-i-bet-builder-history" />
        <span className="u-i-p-l-h-title-bc ellipsis">
          {t("admin.BetBuilderhistory")}
        </span>
        <i className="  count-blink-even " data-badge="" />
        <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-right" />
      </div>
      <div
        className="u-i-p-l-head-bc"
        onClick={() => handleSelectGroup("Bonuses")}
      >
        <i className="user-nav-icon bc-i-promotion" />
        <span className="u-i-p-l-h-title-bc ellipsis">{t("admin.Bonuses")}</span>
        <i className="  count-blink-even " data-badge="" />
        <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-right" />
      </div>
      <div
        className="u-i-p-l-head-bc"
        onClick={() => handleSelectGroup("My Profile")}
      >
        <i className="user-nav-icon bc-i-user" />
        <span className="u-i-p-l-h-title-bc ellipsis">{t("admin.MyProfile")}</span>
        <i className="  count-blink-even " data-badge="" />
        <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-right" />
      </div>
      <div
        className="u-i-p-l-head-bc"
        onClick={() => handleSelectGroup("Bet History")}
      >
        <i className="user-nav-icon bc-i-history" />
        <span className="u-i-p-l-h-title-bc ellipsis">{t("admin.BetHistory")}</span>
        <i className="  count-blink-even " data-badge="" />
        <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-right" />
      </div>
      <div
        className="u-i-p-l-head-bc"
        onClick={() => handleSelectGroup("Messages")}
      >
        <i className="user-nav-icon bc-i-message" />
        <span className="u-i-p-l-h-title-bc ellipsis">{t("admin.Messages")}</span>
        <i className="  count-blink-even " data-badge="" />
        <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-right" />
      </div>
    </>
  );
};

export { UnselectedGrop };
