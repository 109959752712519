import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { UseFormRegister, UseFormWatch } from "react-hook-form";
import { login } from "../../../../../../../common/types/Auth/login";

import "./UsernameInput.scss";

interface IUsernameInput {
  register: UseFormRegister<login>;
  watch: UseFormWatch<login>;
}

const UsernameInput = ({ register, watch }: IUsernameInput) => {
  const [inputValue, setInputValue] = useState("");
  const [defaulError, setDefaultError] = useState(false);
  const [inputFocused, setInputFocuse] = useState(false);
  const watchLogin = watch("username");

  const handleInputFocus = () => {
    setInputFocuse(true);
  };

  const handleInputBlur = () => {
    setInputFocuse(false);
    if (watchLogin?.length > 0) {
      setDefaultError(false);
    } else {
      setDefaultError(true);
    }
  };

  useEffect(() => {
    setInputValue(watchLogin);
    if (watchLogin?.length > 0) {
      setDefaultError(false);
    } else {
      // setDefaultError(true);
    }
  }, [watchLogin]);
  return (
    <div className="entrance-f-item-bc">
      <div
        className={`form-control-bc default ${
          inputFocused || inputValue?.length > 0 ? "focused" : ""
        } ${defaulError ? "invalid" : "valid"}`}
      >
        <label className="form-control-label-bc inputs">
          <input
            className="form-control-input-bc"
            {...register("username", { required: true, minLength: 2 })}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
          />
          <i className="form-control-input-stroke-bc" />
          <span className="form-control-title-bc ellipsis">
            {t("admin.EmailUsername")}
          </span>
        </label>
        {defaulError ? (
          <div className="form-control-message-holder-bc">
            <span className="form-control-message-bc">
              {t("admin.Thisfieldisrequired")}
            </span>
          </div>
        ) : null}
        {inputValue?.length < 2 && inputValue?.length > 0 ? (
          <div className="form-control-message-holder-bc">
            <span className="form-control-message-bc">
              {t("admin.Usernameshouldcontainatleastcharacters")}
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export { UsernameInput };
