import React from "react";
import { Link } from "react-router-dom";
import mainBanner from "accets/images/gif/mainBanner/mainBanner.gif";

import "./PromoSlortar.scss";

const PromoSlortar = () => {
  return (
    <div
      className="hm-row-bc has-slider"
      style={{ gridTemplateColumns: "12fr" }}
    >
      <div className="slider-bc  hide-shadow">
        <div className="carousel">
          <div className="carousel-viewport">
            <div
              className="carousel-container"
              style={{ transform: "translate3d(0%, 0px, 0px)" }}
            >
              <div
                className="carousel-slide active-slide"
                style={{ width: "100%" }}
              >
                <div className="sdr-item-holder-bc">
                  <Link
                    target="_self"
                    className="sdr-item-bc"
                    to="/tr/promotions/all"
                  >
                    <img
                      src={mainBanner}
                      loading="lazy"
                      className="sdr-image-bc"
                      alt=""
                      title="Promotions"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { PromoSlortar };
