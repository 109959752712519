import React from "react";
import { mockCouruselMainType } from "../../../pages/Main/mockCourusel/mockCouruselMain";

import "./CaruselCart.scss";
import { BannersList } from "common/types/Banners/BannersList";
import { Link } from "react-router-dom";
import { useTypeSelector } from "hooks/useTypeSelector";

interface CaruselCart {
 cartCaruselInfo: BannersList;
}

const CaruselCart = ({ cartCaruselInfo }: CaruselCart) => {
 const lang = useTypeSelector(
  (data) => data.settingsReducer.language.languageShort
 );
 return (
  <div className="carousel-slide " style={{ width: "100%", left: "0%" }}>
   <div className="sdr-item-holder-bc">
    <Link
     className="sdr-item-bc"
     to={cartCaruselInfo.url ? `/${lang}/${cartCaruselInfo.url}` : "#"}
    >
     <img
      src={cartCaruselInfo.mobile_image}
      loading="lazy"
      className="sdr-image-bc"
      alt=""
      title={cartCaruselInfo.title}
     />
    </Link>
   </div>
  </div>
 );
};

export { CaruselCart };
