import React, { useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import "./CaruselAmount.scss";
import { useTypeSelector } from "../../../../hooks/useTypeSelector";
import { t } from "i18next";
import { Link } from "react-router-dom";

const CaruselAmount = () => {
 const [caruselShow, setCaruselShow] = useState(1);
 const balance = useTypeSelector(
  (data) => data.accountInfoReducer.balance.balance.amount
 );
 const { amount_bonus } = useTypeSelector(
  (data) => data.accountInfoReducer.balance.balance
 );
 const [showBalance, setShowBalance] = useState(true);
 return (
  <div className="u-i-p-amount-holder-bc">
   <div className="carousel">
    <div className="carousel-viewport">
     <Swiper
      className={`carousel-container swiper-custom ${
       caruselShow === 1 ? "swiper-custom-right" : ""
      } ${caruselShow === 2 ? "swiper-custom-left" : ""}`}
      spaceBetween={30}
      slidesPerView={1.4}
      onSlideChange={(e) => setCaruselShow(e.activeIndex + 1)}
     >
      <SwiperSlide
       className={`carousel-slide  ${
        caruselShow === 1 ? "active-slide" : ""
       } custom-corusel-slide`}
       style={{ maxWidth: "350px" }}
      >
       <div className="u-i-p-amounts-bc withdrawable">
        <div className="u-i-p-a-content-bc">
         <div className="total-balance-r-bc">
          <div className="u-i-p-a-user-balance">
           <span className="u-i-p-a-title-bc ellipsis">
            {t("admin.MainBalance")}
           </span>
           <b className="u-i-p-a-amount-bc">
            {showBalance ? balance.toFixed(2) + " ₺" : "*****"}
           </b>
          </div>
         </div>
         <div className="u-i-p-a-buttons-bc">
          <Link
           className="u-i-p-a-deposit-bc ellipsis"
           to={{
            search: "?profile=open&account=balance&page=deposit",
           }}
          >
           <i className="bc-i-wallet" />
           <span
            className="ellipsis"
            title="Deposit"
            style={{ whiteSpace: "normal" }}
           >
            {t("admin.Deposit")}
           </span>
          </Link>
          <Link
           className="u-i-p-a-withdraw-bc ellipsis"
           to={{
            search: "?profile=open&account=balance&page=withdraw",
           }}
          >
           <i className="bc-i-withdraw" />
           <span className="ellipsis" title="Withdraw">
            {t("admin.Withdraw")}
           </span>
          </Link>
         </div>
        </div>
       </div>
      </SwiperSlide>
      <SwiperSlide
       className={`carousel-slide ${caruselShow === 2 ? "active-slide" : ""}`}
       style={{ maxWidth: "350px" }}
      >
       <div className="u-i-p-amounts-bc bonuses">
        <div className="u-i-p-a-content-bc">
         <span className="u-i-p-a-title-bc ellipsis">
          {t("admin.TotalBonusBalance")}
         </span>
         <span className="u-i-p-a-amount-bc">
          {showBalance ? amount_bonus.toFixed(2) + " ₺" : "*****"}
         </span>
         <div className="bonus-info-section">
          <div>
           <span className="ellipsis">{t("admin.BonusBalance")}</span>
           <b>{showBalance ? amount_bonus.toFixed(2) + " ₺" : "*****"}</b>
          </div>
         </div>
        </div>
       </div>
      </SwiperSlide>
     </Swiper>
    </div>
   </div>
  </div>
 );
};

export { CaruselAmount };
