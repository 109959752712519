import React from "react";

import "./ErrorPage.scss";

import error_page_logo from "../../accets/images/png/error-page-logo.png";
import { useNavigate } from "react-router-dom";
import { useTypeSelector } from "../../hooks/useTypeSelector";
import { Layout } from "../../components/layout";

const ErrorPage = () => {
 const navigate = useNavigate();
 const language = useTypeSelector(
  (data) => data.settingsReducer.language.languageShort
 );

 const moveToHomePage = () => {
  navigate(`/${language}`);
 };
 const { showRedirectInfo } = useTypeSelector((data) => data.settingsReducer);
 return (
  <Layout containerStyles="is-home-page " navHeader="">
   <div
    className="layout-content-holder-bc"
    style={showRedirectInfo ? { marginTop: "60px" } : { marginTop: "0px" }}
   >
    <div className="error-page ">
     <div className="error-page-content">
      <img src={error_page_logo} className="error-page-icon" alt="" />
      <p className="error-page-info">Sayfa bulunamadı</p>
      <button
       className="btn s-small a-color "
       type="button"
       title="ANA SAYFAYA GİT"
       onClick={moveToHomePage}
      >
       <span>ANA SAYFAYA GİT</span>
      </button>
     </div>
    </div>
   </div>
  </Layout>
 );
};

export { ErrorPage };
