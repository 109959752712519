import { t } from "i18next";
import React, { useState } from "react";

import "./StatusSelect.scss";

const StatusSelect = () => {
  const [selectOpen, setSelectOpen] = useState(false);
  const handleChangeSelect = () => {
    setSelectOpen((prev) => !prev);
  };
  return (
    <div className="u-i-p-control-item-holder-bc">
      <div className="multi-select-bc">
        <div className="form-control-bc filled valid">
          <div
            className="form-control-label-bc inputs"
            onClick={handleChangeSelect}
          >
            <div className="form-control-select-bc">
              {t("jackpot.Upcoming")}, {t("admin.Live")}
            </div>
            <i className="form-control-icon-bc bc-i-small-arrow-down" />
            <i className="form-control-input-stroke-bc" />
            <span className="form-control-title-bc ellipsis">
              {t("jackpot.Statuses")}
            </span>
          </div>
          {selectOpen ? (
            <div className="multi-select-label-bc">
              <label className="checkbox-control-content-bc">
                <i className="multi-select-icon-bc undefined" />
                <p className="checkbox-control-text-bc">{t("admin.All")} </p>
                <input type="checkbox" className="checkbox-control-input-bc" />
                <i className="checkbox-control-icon-bc bc-i-checked custom-select" />
              </label>
              <label className="checkbox-control-content-bc">
                <i className="multi-select-icon-bc undefined" />
                <p className="checkbox-control-text-bc">{t("jackpot.Upcoming")} </p>
                <input
                  type="checkbox"
                  className="checkbox-control-input-bc"
                  defaultChecked={false}
                />
                <i className="checkbox-control-icon-bc bc-i-checked custom-select" />
              </label>
              <label className="checkbox-control-content-bc">
                <i className="multi-select-icon-bc undefined" />
                <p className="checkbox-control-text-bc">{t("admin.Live")} </p>
                <input
                  type="checkbox"
                  className="checkbox-control-input-bc"
                  defaultChecked={false}
                />
                <i className="checkbox-control-icon-bc bc-i-checked custom-select" />
              </label>
              <label className="checkbox-control-content-bc">
                <i className="multi-select-icon-bc undefined" />
                <p className="checkbox-control-text-bc">{t("admin.Finished")}</p>
                <input type="checkbox" className="checkbox-control-input-bc" />
                <i className="checkbox-control-icon-bc bc-i-checked custom-select" />
              </label>
              <label className="checkbox-control-content-bc">
                <i className="multi-select-icon-bc undefined" />
                <p className="checkbox-control-text-bc">{t("admin.Canceled")} </p>
                <input
                  type="checkbox"
                  className="checkbox-control-input-bc"
                  defaultChecked={false}
                />
                <i className="checkbox-control-icon-bc bc-i-checked custom-select" />
              </label>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export { StatusSelect };
