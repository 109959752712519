import React from "react";
import { FooterSetteings } from "./components/footerSetteings/FooterSetteings";

import parse from "html-react-parser";
import image1 from "accets/images/png/footerLinksSocial/canlı-logo-png.png";
import image2 from "accets/images/png/footerLinksSocial/facebook.png";
import image3 from "accets/images/png/footerLinksSocial/instagram-logo.png";
import image4 from "accets/images/png/footerLinksSocial/telegram-logo.png";
import image5 from "accets/images/png/footerLinksSocial/x-logo.png";
import image6 from "accets/images/png/footerLinksSocial/youtube-logo.png";
import logoVeri from "accets/images/jpeg/logoVeri.jpeg";
import "./Footer.scss";
import { useTypeSelector } from "hooks/useTypeSelector";
import { BananersEnum } from "common/types/Banners/BannersList";
import { Link } from "react-router-dom";

const Footer = () => {
 const { content } = useTypeSelector(
  (data) => data.settingsReducer.content.mobileFooter
 );
 const FooterTopBaner = useTypeSelector((data) =>
  data.accountInfoReducer.banners.filter(
   (item) => item.type === BananersEnum.FooterTopBaner
  )
 );
 const FooterCenterBaner = useTypeSelector((data) =>
  data.accountInfoReducer.banners.filter(
   (item) => item.type === BananersEnum.FooterCenterBaner
  )
 );
 const FooterBottomBaner = useTypeSelector((data) =>
  data.accountInfoReducer.banners.filter(
   (item) => item.type === BananersEnum.FooterBottomBaner
  )
 );

 const FooterPaymentBanners = useTypeSelector((data) =>
  data.accountInfoReducer.banners.filter(
   (item) => item.type === BananersEnum.FooterPaymentBanners
  )
 );
 return (
  <div className="layout-footer-holder-bc">
   <div className="footer-bc">
    <FooterSetteings />
    <div className="links_container">
     <img src={image1} className="footer_social" />
     <img src={image2} className="footer_social" />
     <img src={image3} className="footer_social" />
     <Link to="https://t.me/casinopalasgiris">
      <img src={image4} className="footer_social" />
     </Link>
     <Link to="https://x.com/casinopalass">
      <img src={image5} className="footer_social" />
     </Link>
     <img src={image6} className="footer_social" />
    </div>
    {content ? parse(content) : null}
    <div></div>
    <div className="regulation-partner">
     <div className="ftr-partners-row-bc">
      <div className="ftr-partners-row-inner-bc">
       <div rel="noreferrer">
        <img
         alt=""
         className="ftr-partners-r-img"
         loading="lazy"
         src={
          FooterTopBaner[0]?.mobile_image ? FooterTopBaner[0]?.mobile_image : ""
         }
        />
       </div>
      </div>
      <div className="ftr-partners-row-inner-bc">
       <div rel="noreferrer">
        <img
         alt=""
         className="ftr-partners-r-img"
         loading="lazy"
         src={
          FooterCenterBaner[0]?.mobile_image
           ? FooterCenterBaner[0]?.mobile_image
           : ""
         }
        />
       </div>
      </div>
      <div className="ftr-partners-row-inner-bc" style={{ display: "flex" }}>
       {FooterBottomBaner[0]?.mobile_image ? (
        <div rel="noreferrer">
         <img
          alt=""
          className="ftr-partners-r-img"
          loading="lazy"
          src={
           FooterBottomBaner[0]?.mobile_image
            ? FooterBottomBaner[0]?.mobile_image
            : ""
          }
          style={{ maxHeight: "100px", maxWidth: "100px", marginRight: "20px" }}
         />
        </div>
       ) : null}
       {FooterBottomBaner[1]?.mobile_image ? (
        <a
         rel="noreferrer"
         target="_blank"
         href="https://licence-curacao-egaming.com/"
        >
         <img
          alt=""
          className="ftr-partners-r-img"
          loading="lazy"
          src={
           FooterBottomBaner[1]?.mobile_image
            ? FooterBottomBaner[1]?.mobile_image
            : ""
          }
          style={{ maxHeight: "100px", maxWidth: "100px", marginRight: "20px" }}
         />
        </a>
       ) : null}
       {FooterBottomBaner[2]?.mobile_image ? (
        <div rel="noreferrer">
         <img
          alt=""
          className="ftr-partners-r-img"
          loading="lazy"
          src={
           FooterBottomBaner[2]?.mobile_image
            ? FooterBottomBaner[2]?.mobile_image
            : ""
          }
          style={{ maxHeight: "100px", maxWidth: "100px", marginRight: "20px" }}
         />
        </div>
       ) : null}
       <a
        target="_blank"
        href="https://licence-curacao-egaming.com/"
        rel="noreferrer"
       >
        <img
         alt=""
         className="ftr-partners-r-img"
         loading="lazy"
         src={logoVeri}
         style={{ maxHeight: "100px", maxWidth: "100px", marginRight: "20px" }}
        />
       </a>
      </div>
     </div>
    </div>
    <div className="ftr-payments-row-bc">
     {FooterPaymentBanners
      ? FooterPaymentBanners.map((item) => {
         return (
          <img
           key={item.id}
           alt="Fixturka"
           className="ftr-payments-row-img-bc"
           id="4338_Fixturka"
           src={item?.mobile_image ? item?.mobile_image : ""}
          />
         );
        })
      : null}
    </div>
   </div>
  </div>
 );
};

export { Footer };
