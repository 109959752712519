import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { GameProviderRequest } from "../../../common/types/Casino/GameProvider";

import "./SelectProviderPopap.scss";

interface ISelectProviderPopap {
  setProviderPopapOpen: React.Dispatch<React.SetStateAction<boolean>>;
  providersList: GameProviderRequest | undefined;
  setSelectedProviders: React.Dispatch<React.SetStateAction<number[]>>;
  selectedProviders: number[];
  setShowByParams: React.Dispatch<React.SetStateAction<boolean>>;
  searchInput: string;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  filteredData: GameProviderRequest | undefined;
}

const SelectProviderPopap = ({
  setProviderPopapOpen,
  providersList,
  setSelectedProviders,
  selectedProviders,
  setShowByParams,
  searchInput,
  handleSearchChange,
  filteredData,
}: ISelectProviderPopap) => {
  const [selectedProviderPrev, setSelectedProviderPrev] =
    useState<number[]>(selectedProviders);

  const handleClosePopap = () => {
    setProviderPopapOpen(false);
  };

  const handleApply = () => {
    setSelectedProviders(selectedProviderPrev);
    setProviderPopapOpen(false);
    setShowByParams(true);
  };

  const handleSelectItem = (item: number[]) => {
    // setSelectedProviderPrev((prevState) => {
    //   if (prevState.includes(item)) {
    //     return prevState.filter((existingId: number) => existingId !== item);
    //   } else {
    //     return [...prevState, item];
    //   }
    // });
    setSelectedProviderPrev((prevState: number[]) => {
      const newState = [...prevState];
      item.forEach((newItem: number) => {
        if (newState.includes(newItem)) {
          newState.splice(newState.indexOf(newItem), 1);
        } else {
          newState.push(newItem);
        }
      });
      return newState;
    });
  };

  const wrapperRef = useRef(null);

  const [inputActive, setInputActive] = useState(false);

  const handleTapOutside = (event: MouseEvent | TouchEvent) => {
    if (
      wrapperRef.current &&
      !(wrapperRef.current as HTMLElement).contains(event.target as Node)
    ) {
      setInputActive(false);
    }
  };

  const handleOpenInput = () => {
    setInputActive((prev) => !prev);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleTapOutside);
    document.addEventListener("touchstart", handleTapOutside);
    return () => {
      document.removeEventListener("mousedown", handleTapOutside);
      document.removeEventListener("touchstart", handleTapOutside);
    };
  }, []);
  return (
    <div className="popup-holder-bc full-screen">
      <div className="popup-middleware-bc">
        <div className="popup-inner-bc">
          <div className="casino-category-providers opened">
            <div className="casino-category-providers-header">
              <h3 className="casino-category-providers-heading">
                {t("casino.Providers")}
              </h3>
              <button
                className="providers-close-button-bc bc-i-close-remove "
                type="button"
                onClick={handleClosePopap}
              />
            </div>
            <div className="casino-category-providers-select">
              <div className="casino-category-provider-count-bc">
                {selectedProviderPrev.length === 0
                  ? "No providers selected"
                  : `${selectedProviderPrev.length} SELECTED`}
              </div>
              <div
                className={`sport-search-bc ${
                  inputActive ? "active" : ""
                } search-custom`}
              >
                {inputActive ? (
                  <input
                    type="text"
                    className="ss-input-bc"
                    placeholder="Search provider..."
                    defaultValue=""
                    value={searchInput}
                    onChange={handleSearchChange}
                  />
                ) : null}
                <div
                  className="ss-icon-holder-bc custom-btn-position"
                  onClick={handleOpenInput}
                >
                  <i
                    className={`ss-icon-bc bc-i-${
                      inputActive ? "close-remove" : "search"
                    }`}
                  />
                </div>
              </div>
            </div>
            <div
              className="casino-providers-list"
              data-scroll-lock-scrollable=""
            >
              {providersList && filteredData ? (
                Object.keys(filteredData).map((item) => {
                  return (
                    <div
                      className={`casino-providers-list-item ${
                        selectedProviderPrev.includes(filteredData[item][0].id)
                          ? "selected"
                          : ""
                      }`}
                      data-badge=""
                      key={filteredData[item][0].id}
                      onClick={() =>
                        handleSelectItem(
                          filteredData[item].map((item) => item.id)
                        )
                      }
                    >
                      {filteredData[item][0].name === "Amusenet"
                        ? "Amusnet"
                        : filteredData[item][0].name}
                      <span className="checked-icon bc-i-checked" />
                    </div>
                  );
                })
              ) : (
                <>
                  {providersList ? (
                    Object.keys(providersList).map((item) => {
                      return (
                        <div
                          className={`casino-providers-list-item ${
                            selectedProviderPrev.includes(
                              providersList[item][0].id
                            )
                              ? "selected"
                              : ""
                          }`}
                          data-badge=""
                          key={providersList[item][0].id}
                          onClick={() =>
                            handleSelectItem(
                              providersList[item].map((item) => item.id)
                            )
                          }
                        >
                          {providersList[item][0].name === "Amusenet"
                            ? "Amusnet"
                            : providersList[item][0].name}
                          <span className="checked-icon bc-i-checked" />
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
            <div className="save-filter-button-bc">
              <button
                className="btn a-color "
                type="button"
                title="Apply"
                onClick={handleApply}
              >
                <span>{t("admin.Apply")}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { SelectProviderPopap };
