import React, { useState } from "react";

import "./TwoFactorAuthentication.scss";

import authentication from "../../../../../../../accets/images/png/profile/authentication.png";
import { t } from "i18next";

const TwoFactorAuthentication = () => {
  const [active, setActive] = useState(true);
  const handleActiveMove = () => {
    setActive((prev) => !prev);
  };
  return (
    <div className="authentication-section">
      <img
        src={authentication}
        alt="authentication"
        className="authentication-image"
      />
      <div className="toggle-wrapper  ">
        <span className="toggle-title ellipsis">
          {t("admin.Activatetwofactorauthentication")}
        </span>
        <i
          className={`ui-kit-toggle ${active ? "active" : ""}`}
          onClick={handleActiveMove}
        />
      </div>
    </div>
  );
};

export { TwoFactorAuthentication };
