import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";

import "./BetBuilderhistory.scss";

interface IBetBuilderhistory {
  setSelectedGroup: React.Dispatch<React.SetStateAction<string>>;
}

const BetBuilderhistory = ({ setSelectedGroup }: IBetBuilderhistory) => {
  const handleBack = () => {
    setSelectedGroup("");
  };
  return (
    <>
      <div className="u-i-p-l-head-bc" onClick={handleBack}>
        <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-left" />
        <span className="u-i-p-l-h-title-bc ellipsis">
          {t("admin.BetBuilderhistory")}
        </span>
      </div>
      <div className="u-i-p-l-body-bc">
        <Link
          className="u-i-p-l-head-bc"
          to={{
            search: "?profile=open&account=bet-builder-history&page=bets",
          }}
        >
          <i className="user-nav-icon bc-i-bet-history" />
          <span className="u-i-p-l-h-title-bc ellipsis">{t("admin.All")}</span>
          <i className="  count-blink-even " data-badge="" />
          <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-right" />
        </Link>
        <Link
          className="u-i-p-l-head-bc"
          to={{
            search: "?profile=open&account=bet-builder-history&page=open-bets",
          }}
        >
          <i className="user-nav-icon bc-i-open-bets" />
          <span className="u-i-p-l-h-title-bc ellipsis">{t("admin.Openbets")}</span>
          <i className="  count-blink-even " data-badge="" />
          <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-right" />
        </Link>
        <Link
          className="u-i-p-l-head-bc"
          to={{
            search: "?profile=open&account=bet-builder-history&page=won",
          }}
        >
          <i className="user-nav-icon bc-i-won" />
          <span className="u-i-p-l-h-title-bc ellipsis">{t("admin.Won")}</span>
          <i className="  count-blink-even " data-badge="" />
          <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-right" />
        </Link>
        <Link
          className="u-i-p-l-head-bc"
          to={{
            search: "?profile=open&account=bet-builder-history&page=lost",
          }}
        >
          <i className="user-nav-icon bc-i-lost" />
          <span className="u-i-p-l-h-title-bc ellipsis">{t("admin.Lost")}</span>
          <i className="  count-blink-even " data-badge="" />
          <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-right" />
        </Link>
      </div>
    </>
  );
};

export { BetBuilderhistory };
