import { t } from "i18next";
import React from "react";
import { useDispatch } from "react-redux";
import { RegisterForm } from "./RegisterForm/RegisterForm";

import logo from "../../../../accets/images/png/logo.png";

import "./RegisterModal.scss";
import { useTypeSelector } from "../../../../hooks/useTypeSelector";
import { Link } from "react-router-dom";
import { setModalLogin, setModalRegister } from "store/reducers/modalsReducer";

interface IRegisterModal {
  handleClosePopap: () => void;
}

const RegisterModal = ({ handleClosePopap }: IRegisterModal) => {
  const dispatch = useDispatch();
  const language = useTypeSelector(
    (data) => data.settingsReducer.language.languageShort
  );
  const handleOpenAuthForm = () => {
    dispatch(setModalLogin(false));
    dispatch(setModalRegister(true));
  };
  return (
    <div className="entrance-popup-bc register">
      <div className="e-p-content-holder-bc">
        <div className="e-p-content-bc">
          <div className="e-p-header-bc">
            <Link className="popup-t-logo-w-bc" to={`/${language}/`}>
              <img className="hdr-logo-bc" src={logo} alt="" />
            </Link>
            <div className="e-p-sections-bc" onClick={handleOpenAuthForm}>
              <div className="e-p-section-item-bc">
                <span className="e-p-section-title-bc">
                  {t("admin.Signin")}
                </span>
              </div>
            </div>
          </div>
          <div className="e-p-body-bc">
            <div className="reg-form-block-bc">
              <RegisterForm handleClosePopap={handleClosePopap} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { RegisterModal };
