import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { useDispatch } from "react-redux";
import { RouteWrapper } from "./RoutWrapper";
import { AdminPanel, CasinoInfo } from "../services";
import { useTypeSelector } from "../hooks/useTypeSelector";
// import loadGit from "./accets/images/gif/loader/loader.gif";
import loadGit from "../accets/images/png/logo.png";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import {
 Sport,
 Live,
 Main,
 Jackpot,
 Tournaments,
 Promotions,
 Aviator,
 Zeplin,
 SpaceMan,
 LiveCasino,
 ErrorPage,
 Casino,
 Games,
 CanliTakvim,
 BahisTurnament,
 LiveCalendar,
 TournamentsSports,
 Betconstruct,
 EdgeGaming,
 LeapGaming,
 Kiron,
} from "pages";
import {
 setUserBalance,
 setAuth,
 setUserInfo,
 setUserInit,
 setUserBalanceInfo,
} from "store/reducers/accountInfoReducer";
import { setFavoritsUser } from "store/reducers/favoritsReducer";
import { setContent } from "store/reducers/settingsReducer";

window.Pusher = Pusher;

declare global {
 interface Window {
  Pusher: typeof Pusher;
 }
}

declare global {
 interface Window {
  Echo: any;
 }
}

function RedirectToMain() {
 const language = useTypeSelector(
  (data) => data.settingsReducer.language.languageShort
 );
 return <Navigate to={`/${language}/`} />;
}

const Navigation = () => {
 const { en } = useParams();
 const bearerToken = localStorage.getItem("token");
 const user = useTypeSelector((data) => data.accountInfoReducer.user);

 const [loading, setLoading] = useState(true);
 const dispatch = useDispatch();
 async function getFavoritsGames() {
  const res = await CasinoInfo.favoritGames();
  dispatch(setFavoritsUser(res.data));
 }

 async function userBalance() {
  try {
   const resbalance = await AdminPanel.userBalance();
   dispatch(setUserBalanceInfo(resbalance.data));
   dispatch(setAuth("player"));
   getFavoritsGames();
   setTimeout(() => {
    setLoading(false);
   }, 1000);
  } catch (error) {
   console.error(error);
   localStorage.removeItem("token");
  } finally {
   setLoading(false);
  }
 }

 async function userContent() {
  const lang = `lang=${en || "tr"}`;
  try {
   const res = await CasinoInfo.contact(lang);
   dispatch(setContent(res.data));
  } catch (e) {
   console.error(e);
  }
 }

 async function userInfo() {
  try {
   const res = await AdminPanel.userInfo();
   dispatch(setUserInfo(res.data));
  } catch (error) {
   localStorage.removeItem("token");
  }
 }

 useEffect(() => {
  userContent();
  const token = localStorage.getItem("token");
  const isAuthenticated = !!token;

  if (isAuthenticated) {
   userBalance();
   userInfo();
  } else {
   dispatch(setUserInit());
   setTimeout(() => {
    setLoading(false);
   }, 500);
  }
 }, []);
 if (bearerToken) {
  const echo = new Echo({
   broadcaster: "pusher",
   key: "b96900e87fe4fad222df",
   cluster: "eu",
   encrypted: true,
   forceTLS: true,
   authEndpoint: "https://casino-api.services/broadcasting/auth",
   auth: { headers: { Authorization: "Bearer " + bearerToken } },
  });

  echo
   .private("user-" + user.api_user_id)
   .listen("BalanceUpdated", (e: { amount: number; amount_bonus: number }) => {
    const amount = e.amount / 100;
    dispatch(setUserBalance(amount));
   })
   .listen("UpdateUserBonusEvent", async () => {
    const resbalance = await AdminPanel.userBalance();
    dispatch(setUserBalanceInfo(resbalance.data));
   })
   .listen("BonusTargetEvent", async () => {
    const resbalance = await AdminPanel.userBalance();
    dispatch(setUserBalanceInfo(resbalance.data));
   })
   .listen("BonusDepositEvent", async () => {
    const resbalance = await AdminPanel.userBalance();
    dispatch(setUserBalanceInfo(resbalance.data));
   });
 }
 return (
  <>
   {loading ? (
    <div className="layout-bc">
     <div className="bc-loader medium ">
      <div className="bc-loader-contain">
       <img src={loadGit} alt="" className="animated-image" />
      </div>
     </div>
    </div>
   ) : (
    <Router>
     <Routes>
      <Route
       path={"/:en/sports/pre-match/event-view"}
       element={<RouteWrapper element={<Sport />} />}
      />
      <Route
       path={"/:en/sports/live/event-view"}
       element={<RouteWrapper element={<Live />} />}
      />
      <Route path={"/:en"} element={<RouteWrapper element={<Main />} />} />
      <Route path="/" element={<RedirectToMain />} />
      <Route
       path={"/:en/casino/jackpot"}
       element={<RouteWrapper element={<Jackpot />} />}
      />
      {/* <Route
              path={"/:en/wonderwheel"}
              element={<RouteWrapper element={<MaltbahisCarck />} />}
            /> */}
      <Route
       path={"/:en/casino/tournaments"}
       element={<RouteWrapper element={<Tournaments />} />}
      />
      <Route
       path={"/:en/promotions/all"}
       element={<RouteWrapper element={<Promotions />} />}
      />
      <Route
       path={"/:en/promotions/all/:id"}
       element={<RouteWrapper element={<Promotions />} />}
      />
      <Route
       path={"/:en/casino/game-view/806666"}
       element={<RouteWrapper element={<Aviator />} />}
      />
      <Route
       path={"/:en/casino/game-view/9010"}
       element={<RouteWrapper element={<Zeplin />} />}
      />
      <Route
       path={"/:en/casino/game-view/400043028"}
       element={<RouteWrapper element={<SpaceMan />} />}
      />
      <Route
       path={"/:en/live-casino/home/-1/All"}
       element={<RouteWrapper element={<LiveCasino />} />}
      />
      <Route path={"*"} element={<RouteWrapper element={<ErrorPage />} />} />
      <Route
       path={"/:en/casino/slots/-1"}
       element={<RouteWrapper element={<Casino />} />}
      />
      <Route
       path={"/:en/games"}
       element={<RouteWrapper element={<Games />} />}
      />
      <Route
       path={"/:en/sports/live-calendar"}
       element={<RouteWrapper element={<CanliTakvim />} />}
      />
      <Route
       path={"/:en/sports/tournaments"}
       element={<RouteWrapper element={<BahisTurnament />} />}
      />
      <Route
       path={"/:en/sports/pre-match/live-calendar"}
       element={<RouteWrapper element={<LiveCalendar />} />}
      />
      <Route
       path={"/:en/sports/pre-match/tournaments"}
       element={<RouteWrapper element={<TournamentsSports />} />}
      />
      <Route
       path={"/:en/virtual-sports/betconstruct"}
       element={<RouteWrapper element={<Betconstruct />} />}
      />
      <Route
       path={"/:en/virtual-sports/ext/game/155000"}
       element={<RouteWrapper element={<EdgeGaming />} />}
      />
      <Route
       path={"/:en/virtual-sports/ext/game/4000235"}
       element={<RouteWrapper element={<LeapGaming />} />}
      />
      <Route
       path={"/:en/virtual-sports/ext/game/40004001"}
       element={<RouteWrapper element={<Kiron />} />}
      />
     </Routes>
    </Router>
   )}
  </>
 );
};

export { Navigation };
