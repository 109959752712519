import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "./InboxPage.scss";

const InboxPage = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  });

  return (
    <div className="messageBoxWrapper-bc">
      {loading ? (
        <ul className="messageBoxList-bc">
          <div className="message-block-bc" data-status="read">
            <div className="message-title-bc skeleton-loader-text" />
            <div className="message-more-time-bc">
              <span className="skeleton-loader-full-text" />
            </div>
          </div>
          <div className="message-block-bc" data-status="read">
            <div className="message-title-bc skeleton-loader-text" />
            <div className="message-more-time-bc">
              <span className="skeleton-loader-full-text" />
            </div>
          </div>
        </ul>
      ) : (
        <>
          <p className="empty-b-text-v-bc">{t("admin.Youdonothaveanymessages")}</p>
          <Link
            className="btn a-color"
            to={{
              search: "profile=open&account=messages&page=new",
            }}
          >
            {t("admin.Newmessage")}
          </Link>
        </>
      )}
    </div>
  );
};

export { InboxPage };
