import { t } from "i18next";
import React, { useState } from "react";
import { UseFormRegister, UseFormWatch } from "react-hook-form";
import { changePassword } from "../../../../../../../../../common/types/Auth/changePassword";

import "./NewPassword.scss";

interface INewPassword {
  register: UseFormRegister<changePassword>;
  watch: UseFormWatch<changePassword>;
}

const NewPassword = ({ register, watch }: INewPassword) => {
  const currentPassword = watch("password");
  const [inputOpen, setInputOpen] = useState(true);
  const onFocus = () => {
    setInputOpen(true);
  };
  const onBlur = () => {
    if (currentPassword.length > 0) {
      setInputOpen(true);
    } else {
      setInputOpen(false);
    }
  };
  return (
    <div className="u-i-p-control-item-holder-bc">
      <div
        className={`form-control-bc default has-icon ${
          inputOpen ? "focused" : ""
        }`}
      >
        <label className="form-control-label-bc inputs">
          <input
            type="password"
            className="form-control-input-bc"
            autoComplete="current-password"
            {...register("password", {required: true})}
            onFocus={onFocus}
            onBlur={onBlur}
          />
          <i className="form-control-input-stroke-bc" />
          <span className="form-control-title-bc ellipsis">
            {t("admin.Confirmnewpassword")}
          </span>
        </label>
      </div>
    </div>
  );
};

export { NewPassword };
