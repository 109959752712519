import { t } from "i18next";
import React, { useState } from "react";
import { EntryTypeSelect } from "./EntryTypeSelect/EntryTypeSelect";
import { RegistrationStatusSelect } from "./RegistrationStatusSelect/RegistrationStatusSelect";
import { StatusSelect } from "./StatusSelect/StatusSelect";

import "./TournamentsFilter.scss";

interface ITournamentsFilter {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const TournamentsFilter = ({ setLoading }: ITournamentsFilter) => {
  const [filterOpen, setFilterOpen] = useState(false);
  const handleOpenFilter = () => {
    setFilterOpen((prev) => !prev);
  };
  const handleShow = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    setFilterOpen(false);
  };
  return (
    <div className="casino-game-tournament-filter custom">
      <div className="componentFilterWrapper-bc">
        <div
          className={`componentFilterLabel-bc ${filterOpen ? "active" : ""}`}
          onClick={handleOpenFilter}
        >
          <i className="componentFilterLabel-filter-i-bc bc-i-filter" />
          <div className="componentFilterLabel-filter-bc">
            <p className="ellipsis">{t("admin.Filter")}</p>
          </div>
          <i className="componentFilterChevron-bc bc-i-small-arrow-down" />
        </div>
        <div className="componentFilterBody-bc">
          <div className="componentFilterElsWrapper-bc">
            <div className="filter-form-w-bc">
              <StatusSelect />
              <RegistrationStatusSelect />
              <EntryTypeSelect />
              <div className="u-i-p-c-footer-bc">
                <button
                  className="btn a-color "
                  type="submit"
                  title="Show"
                  onClick={handleShow}
                >
                  <span>{t("admin.Show")}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { TournamentsFilter };
