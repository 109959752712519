import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";

import "./Bonuses.scss";

interface IBetBuilderhistory {
  setSelectedGroup: React.Dispatch<React.SetStateAction<string>>;
}

const Bonuses = ({ setSelectedGroup }: IBetBuilderhistory) => {
  const handleBack = () => {
    setSelectedGroup("");
  };
  return (
    <>
      <div className="u-i-p-l-head-bc" onClick={handleBack}>
        <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-left" />
        <span className="u-i-p-l-h-title-bc ellipsis">
          {t("admin.Bonuses")}
        </span>
      </div>
      <div className="u-i-p-l-body-bc">
        <Link
          className="u-i-p-l-head-bc"
          to={{
            search: "?profile=open&account=bonuses&page=loyalty-points",
          }}
        >
          <i className="user-nav-icon bc-i-loyalty-points" />
          <span className="u-i-p-l-h-title-bc ellipsis">
            {t("admin.LoyaltyPoints")}
          </span>
          <i className="  count-blink-even " data-badge="" />
          <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-right" />
        </Link>
        <Link
          className="u-i-p-l-head-bc"
          to={{
            search: "?profile=open&account=bonuses&page=exchange-shop",
          }}
        >
          <i className="user-nav-icon bc-i-exchange-shop" />
          <span className="u-i-p-l-h-title-bc ellipsis">
            {t("admin.ExchangeShop")}
          </span>
          <i className="  count-blink-even " data-badge="" />
          <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-right" />
        </Link>
        <Link
          className="u-i-p-l-head-bc"
          to={{
            search: "?profile=open&account=bonuses&page=sport",
          }}
        >
          <i className="user-nav-icon bc-i-bonus-sportsbook" />
          <span className="u-i-p-l-h-title-bc ellipsis">
            {t("admin.SportBonus")}
          </span>
          <i className="  count-blink-even " data-badge="" />
          <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-right" />
        </Link>
        <Link
          className="u-i-p-l-head-bc"
          to={{
            search: "?profile=open&account=bonuses&page=bonus-history",
          }}
        >
          <i className="user-nav-icon bc-i-history" />
          <span className="u-i-p-l-h-title-bc ellipsis">
            {t("admin.Bonushistory")}
          </span>
          <i className="  count-blink-even " data-badge="" />
          <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-right" />
        </Link>
        <Link
          className="u-i-p-l-head-bc"
          to={{
            search: "?profile=open&account=bonuses&page=casino",
          }}
        >
          <i className="user-nav-icon bc-i-bonus-casino" />
          <span className="u-i-p-l-h-title-bc ellipsis">
            {t("admin.CasinoBonus")}
          </span>
          <i className="  count-blink-even " data-badge="" />
          <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-right" />
        </Link>
        <Link
          className="u-i-p-l-head-bc"
          to={{
            search: "?profile=open&account=bonuses&page=promo-code",
          }}
        >
          <i className="user-nav-icon bc-i-promo-code" />
          <span className="u-i-p-l-h-title-bc ellipsis">
            {t("admin.Promocode")}
          </span>
          <i className="  count-blink-even " data-badge="" />
          <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-right" />
        </Link>
        <Link
          className="u-i-p-l-head-bc"
          to={{
            search: "?profile=open&account=bonuses&page=casino-free-spins",
          }}
        >
          <i className="user-nav-icon bc-i-bonus" />
          <span className="u-i-p-l-h-title-bc ellipsis">
            {t("admin.CasinoFreeSpins")}
          </span>
          <i className="  count-blink-even " data-badge="" />
          <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-right" />
        </Link>
      </div>
    </>
  );
};

export { Bonuses };
