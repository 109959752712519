import React, { ReactNode, useEffect, useState } from "react";
import { UserAdmin } from "../useradmin/UserAdmin";
import { Betslip } from "./betslip/Betslip";
import { Footer } from "./footer/Footer";
import { FooterNavigation } from "./footerNavigation/FooterNavigation";
import { Header } from "./header/Header";
import { PopapMeneger } from "./popapMeneger/PopapMeneger";
import { SmartPanel } from "./smartPanel/SmartPanel";

import "./index.scss";
import { useSearchParams } from "react-router-dom";
import { SettingsModal } from "./modals/SettingsModal/SettingsModal";
import { NotificationModal } from "./modals/NotificationModal/NotificationModal";
import { FavoritsModal } from "./modals/FavoritsModal/FavoritsModal";
import { FooterModal } from "./footerModal/FooterModal";
import { PageType } from "common/types/PageType/PageType";
import { CasinoInfo } from "services";
import { RedirectInfo } from "./header/components/redirectInfo/RedirectInfo";
import { useTypeSelector } from "hooks/useTypeSelector";

interface ILayout {
 children: ReactNode;
 containerStyles: string;
 navHeader: string;
 loadStop?: boolean;
 footerNav?: string;
 footerNavType?: string;
}

const Layout = ({
 children,
 containerStyles,
 navHeader,
 loadStop,
 footerNav,
 footerNavType,
}: ILayout) => {
 const [prevScrollY, setPrevScrollY] = useState(0);
 const [headerHidden, setHeaderHidden] = useState(false);

 const [betSlipHidden, setbetSlipHidden] = useState(false);

 const [isFooterContent, setIsFooterContent] = useState(false);
 const [footerPageInfo, setFooterPageInfo] = useState<PageType | null>(null);

 const [centerButtonVisivaly, setCenterButtonVisivaly] = useState(false);

 const [smartPanelVisibale, setSmartPanelVisibale] = useState(false);

 const [menuNavigationVisibaly, setMenuNavigationVisibaly] = useState(false);

 const [useAdminVisibali, setUseAdminVisibali] = useState(true);

 const [settingsOpen, setSettingsOpen] = useState(false);
 const [notificationOpen, setNotificationOpen] = useState(false);
 const [favoritsOpen, setFavoritsOpen] = useState(false);

 const [searchParams, setSearchParams] = useSearchParams();

 const { showRedirectInfo } = useTypeSelector((data) => data.settingsReducer);

 const profile = searchParams.get("profile") || "";
 const helpPageContent = searchParams.get("helpPageContent") || "";

 async function getFooterPage() {
  try {
   const res = await CasinoInfo.page(helpPageContent);
   setFooterPageInfo(res.data);
   setIsFooterContent(true);
  } catch (e) {
   console.error(e);
  }
 }

 useEffect(() => {
  helpPageContent && getFooterPage();
 }, [helpPageContent]);

 useEffect(() => {
  const handleScroll = () => {
   const currentScrollY = window.pageYOffset;

   if (currentScrollY < prevScrollY) {
    setHeaderHidden(false);
    setCenterButtonVisivaly(false);
   } else {
    if (currentScrollY > 230) {
     setHeaderHidden(true);
     setCenterButtonVisivaly(true);
     setTimeout(() => {
      setCenterButtonVisivaly(false);
     }, 5000);
    }
   }

   setPrevScrollY(currentScrollY);
  };

  window.addEventListener("scroll", handleScroll);

  return () => {
   window.removeEventListener("scroll", handleScroll);
  };
 }, [prevScrollY]);
 return (
  <div
   className={`layout-bc theme-default ${containerStyles} ${
    betSlipHidden ? "betslip-Hidden" : ""
   } ${headerHidden ? "header-bc-hide" : ""} ${
    smartPanelVisibale ? "smart-panel-is-visible" : ""
   } ${menuNavigationVisibaly ? "navigation-is-visible" : ""} ${
    useAdminVisibali ? "overlay-sliding-is-visible" : ""
   } custom-layout`}
  >
   <RedirectInfo
   />
   <div className="Toastify" />
   <SmartPanel
    setSmartPanelVisibale={setSmartPanelVisibale}
    setSettingsOpen={setSettingsOpen}
    setNotificationOpen={setNotificationOpen}
    setFavoritsOpen={setFavoritsOpen}
    setbetSlipHidden={setbetSlipHidden}
   />
   <Header
    setSmartPanelVisibale={setSmartPanelVisibale}
    smartPanelVisibale={smartPanelVisibale}
    headerHidden={headerHidden}
    centerButtonVisivaly={centerButtonVisivaly}
    menuNavigationVisibaly={menuNavigationVisibaly}
    setMenuNavigationVisibaly={setMenuNavigationVisibaly}
    navHeader={navHeader}
   />
   {footerNavType !== "casino" ? (
    <Footer />
   ) : (
    <>{loadStop ? <Footer /> : null}</>
   )}
   {footerNav === "none" ||
   navHeader === "aviator" ||
   navHeader === "zeplin" ||
   navHeader === "maltbahiscarck" ||
   navHeader === "spaceman" ? null : (
    <FooterNavigation
     setbetSlipHidden={setbetSlipHidden}
     setMenuNavigationVisibaly={setMenuNavigationVisibaly}
     footerNav={footerNav}
    />
   )}

   <Betslip betSlipHidden={betSlipHidden} setbetSlipHidden={setbetSlipHidden} />
   <PopapMeneger />
   {children}
   {profile === "open" ? <UserAdmin /> : null}
   {settingsOpen ? <SettingsModal setSettingsOpen={setSettingsOpen} /> : null}
   {notificationOpen ? (
    <NotificationModal setNotificationOpen={setNotificationOpen} />
   ) : null}
   {favoritsOpen ? <FavoritsModal setFavoritsOpen={setFavoritsOpen} /> : null}
   {isFooterContent && footerPageInfo ? (
    <FooterModal
     setIsFooterContent={setIsFooterContent}
     footerPageInfo={footerPageInfo}
     setFooterPageInfo={setFooterPageInfo}
    />
   ) : null}
  </div>
 );
};

export { Layout };
