import { t } from "i18next";
import React, { useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { userDeposit } from "../../../../../common/types/AdminInfo/userDeposit";

import "./BalanceAccount.scss";
import { BalanceAccountNav } from "./navigation/BalanceAccountNav/BalanceAccountNav";
import { BuddyTransferPage } from "./pages/BuddyTransferPage/BuddyTransferPage";
import { DepositPage } from "./pages/DepositPage/DepositPage";
import { PayForm } from "./pages/DepositPage/PayForm/PayForm";
import { TransactionHistoryPage } from "./pages/TransactionHistoryPage/TransactionHistoryPage";
import { WithdrawsPage } from "./pages/WithdrawsPage/WithdrawsPage";
import { WithdrawPayForm } from "./pages/WithdrowPage/WithdrawPayForm/WithdrawPayForm";
import { WithdrowPage } from "./pages/WithdrowPage/WithdrowPage";

const BalanceAccount = () => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<userDeposit>();
  const location = useLocation();
  const nanvigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") || "";
  const selectedMethod = searchParams.get("selectedMethod") || "";
  const handleGoBack = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete("account");
    searchParams.delete("page");
    nanvigate({
      search: searchParams.toString(),
    });
  };
  return (
    <>
      <div className="back-nav-bc" onClick={handleGoBack}>
        <i className="back-nav-icon-bc bc-i-round-arrow-left" />
        <span className="back-nav-title-bc ellipsis">
          {t("admin.BalanceManagement")}
        </span>
      </div>
      <BalanceAccountNav page={page} />
      {page === "deposit" ? (
        <DepositPage setSelectedPaymentMethod={setSelectedPaymentMethod} />
      ) : null}
      {page === "withdraw" ? (
        <WithdrowPage setSelectedPaymentMethod={setSelectedPaymentMethod} />
      ) : null}
      {page === "history" ? <TransactionHistoryPage /> : null}
      {page === "withdraws" ? <WithdrawsPage /> : null}
      {page === "buddy-transfer" ? <BuddyTransferPage /> : null}
      {selectedMethod.length > 0 && page === "deposit" ? (
        <PayForm selectedPaymentMethod={selectedPaymentMethod} />
      ) : null}
      {selectedMethod.length > 0 && page === "withdraw" ? (
        <WithdrawPayForm selectedPaymentMethod={selectedPaymentMethod} />
      ) : null}
    </>
  );
};

export { BalanceAccount };
