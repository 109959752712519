import { t } from "i18next";
import React from "react";

import "./Betslip.scss";

interface IBetslip {
  betSlipHidden: boolean;
  setbetSlipHidden: React.Dispatch<React.SetStateAction<boolean>>;
}

const Betslip = ({ betSlipHidden, setbetSlipHidden }: IBetslip) => {
  const handleCloseetslip = () => {
    setbetSlipHidden(false);
  };
  return (
    <div className="layout-betslip-holder-bc">
      {!betSlipHidden ? null : (
        <div
          className="betslip-bc Full  full"
          style={{ transform: "translateY(0px)", opacity: 1 }}
        >
          <div id="betslip_container">
            <div className="betslip-full-content-bc">
              <div className="bs-f-header-bc">
                <div className="bs-f-header-top-stage-bc">
                  <span className="bs-f-h-t-t-balance-bc">0.00 ₺</span>
                  <i
                    className="bs-f-h-t-close-icon-bc bc-i-close-remove custom-remove"
                    onClick={handleCloseetslip}
                  />
                </div>
                <div className="second-tabs-bc">
                  <div
                    className="tab-bc selected-underline betslip active"
                    title="BetSlip"
                  >
                    <span>{t("admin.BetSlip")}</span>
                  </div>
                  <div
                    className="tab-bc selected-underline open-bets"
                    title="Open Bets"
                  >
                    <span>{t("admin.OpenBets")}</span>
                  </div>
                </div>
                <div className="settings-r-b-row-bc">
                  <div className="form-control-bc select s-small has-icon filled">
                    <label className="form-control-label-bc inputs">
                      <i className="select-icon-bc bc-i-settings" />
                      <select className="form-control-select-bc ellipsis">
                        <option value={0}>{t("admin.Alwaysask")}</option>
                        <option value={1}>{t("admin.AcceptHigherodds")}</option>
                        <option value={2}>{t("admin.AcceptAnyodds")}</option>
                      </select>
                      <i className="form-control-icon-bc bc-i-small-arrow-down" />
                    </label>
                  </div>
                </div>
              </div>
              <div className="bs-f-header-sub-navigation-bc " />
              <div className="bs-f-body-bc">
                <div
                  className="bs-scroll-container-bc"
                  data-scroll-lock-scrollable=""
                >
                  <p className="empty-b-text-v-bc">{t("admin.Yourbetslipisempty")}</p>
                  <div className="bs-actions-bc" />
                </div>
              </div>
              <div className="bs-f-footer ">
                <div className="bet-slip-switcher-wrapper-bc" />
                <div className="bs-info-massages" />
                <div className="bet-button-wrapper-bc content-type-0">
                  <button
                    className="btn a-color button-type-0 "
                    type="button"
                    title="Bet Now"
                    disabled={true}
                  >
                    <span>{t("admin.BetNow")}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export { Betslip };
