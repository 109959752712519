import React from "react";
import { useNavigate } from "react-router-dom";
import { useTypeSelector } from "../../../../hooks/useTypeSelector";

import "./SmartPaneAuth.scss";
interface IhandleClick {
  profile: string;
  account: string;
  page: string;
}

interface ISmartPaneAuth {
  setSettingsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSmartPanelVisibale: React.Dispatch<React.SetStateAction<boolean>>;
  setNotificationOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setFavoritsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setbetSlipHidden: React.Dispatch<React.SetStateAction<boolean>>;
}

const SmartPaneAuth = ({
  setSettingsOpen,
  setSmartPanelVisibale,
  setNotificationOpen,
  setFavoritsOpen,
  setbetSlipHidden,
}: ISmartPaneAuth) => {
  const favoritCasinoUser = useTypeSelector(
    (data) => data.favoritsReducer.favoritCasinoUser
  );
  const navigate = useNavigate();
  const handleClick = ({ profile, account, page }: IhandleClick) => {
    const params = new URLSearchParams({
      profile,
      account,
      page,
    });
    navigate(`?${params.toString()}`, { replace: true });
    setSmartPanelVisibale(false);
    setSettingsOpen(false);
    setNotificationOpen(false);
  };
  const handleOpenSetting = () => {
    setSettingsOpen(true);
    setSmartPanelVisibale(false);
    setNotificationOpen(false);
  };
  const handleOpenNotifications = () => {
    setNotificationOpen(true);
    setSmartPanelVisibale(false);
    setSettingsOpen(false);
    setFavoritsOpen(false);
  };
  const handleOpenFavorits = () => {
    setFavoritsOpen(true);
    setSmartPanelVisibale(false);
    setSettingsOpen(false);
    setNotificationOpen(false);
  };
  const handleOpenBetslip = () => {
    setbetSlipHidden(true);
    setSmartPanelVisibale(false);
    setSettingsOpen(false);
    setNotificationOpen(false);
  };
  return (
    <div className="hdr-smart-panel-holder-bc">
      <button
        className="sp-button-bc "
        title="Bet History"
        data-component=""
        onClick={() =>
          handleClick({ profile: "open", account: "history", page: "bets" })
        }
      >
        <i className="sp-button-icon-bc bc-i-history" />
      </button>
      <button
        className="sp-button-bc "
        title="What's new"
        data-component="Notifications"
        onClick={handleOpenNotifications}
      >
        <i
          id="smart-panel-notification-button-id"
          className="sp-button-icon-bc bc-i-notification"
        />
        <i className="  count-blink-even " data-badge="" />
      </button>
      <button
        className="sp-button-bc "
        title="Favorites"
        data-component="FavoriteGames"
        onClick={handleOpenFavorits}
      >
        <i
          id="smart-panel-favorites-button-id"
          className="sp-button-icon-bc bc-i-star"
        />
        <i
          className={` ${
            favoritCasinoUser.length > 0 ? "count-odd-animation" : ""
          } count-blink-odd `}
          data-badge={favoritCasinoUser.length}
        />
      </button>
      <button
        className="sp-button-bc "
        title="Messages"
        data-component="Messages"
        onClick={() =>
          handleClick({ profile: "open", account: "messages", page: "inbox" })
        }
      >
        <i className="sp-button-icon-bc bc-i-message" />
        <i className="  count-blink-even " data-badge="" />
      </button>
      <button
        className="sp-button-bc "
        title="Bonuses"
        data-component="Bonuses"
        onClick={() =>
          handleClick({ profile: "open", account: "bonuses", page: "sport" })
        }
      >
        <i className="sp-button-icon-bc bc-i-bonus" />
        <i className="  count-blink-even " data-badge="" />
      </button>
      <button
        className="sp-button-bc "
        title="Settings"
        data-component="Settings"
        onClick={handleOpenSetting}
      >
        <i
          id="smart-panel-settings-button-id"
          className="sp-button-icon-bc bc-i-settings"
        />
        <span className="sp-button-content-text-bc" />
      </button>
      <button className="sp-button-bc " type="button" title="Contact support">
        <i className="sp-button-icon-bc bc-i-live-chat" />
      </button>
      <button
        className="sp-button-bc "
        title="BetSlip"
        data-component="Betslip"
        onClick={handleOpenBetslip}
      >
        <i className="sp-button-icon-bc bc-i-betslip" />
        <i className="  count-blink-even " data-badge="" />
      </button>
    </div>
  );
};

export { SmartPaneAuth };
