import React, { useState } from "react";

import "./JackpotSearch.scss";

interface IJackpotSearch {
  seacrhGames: string;
  setSeacrhGames: React.Dispatch<React.SetStateAction<string>>;
}

const JackpotSearch = ({ seacrhGames, setSeacrhGames }: IJackpotSearch) => {
  const [inputOpen, setInputOpen] = useState(false);
  const handleOpenInput = () => {
    setInputOpen((prev) => !prev);
  };
  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSeacrhGames(e.target.value);
  };
  return (
    <div className="casino-jackpot-search casino-title-search">
      <div className={`sport-search-bc ${inputOpen ? "active" : ""}`}>
        <input
          type="text"
          className="ss-input-bc"
          placeholder="Search casino game"
          defaultValue=""
          value={seacrhGames}
          onChange={handleChangeValue}
        />
        <div className="ss-icon-holder-bc" onClick={handleOpenInput}>
          <i
            className={`ss-icon-bc bc-i-${
              inputOpen ? "close-remove" : "search"
            } `}
          />
        </div>
      </div>
    </div>
  );
};

export { JackpotSearch };
