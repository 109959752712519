import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";

import "./BBHistoryNav.scss";

interface IBBHistoryNav {
  page: string;
}

const BBHistoryNav = ({ page }: IBBHistoryNav) => {
  return (
    <div className="hdr-navigation-scrollable-bc">
      <div
        className="hdr-navigation-scrollable-content"
        data-scroll-lock-scrollable=""
      >
        <Link
          className={`hdr-navigation-link-bc ${
            page === "bets" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=bet-builder-history&page=bets",
          }}
        >
          {t("admin.All")}
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <Link
          className={`hdr-navigation-link-bc ${
            page === "open-bets" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=bet-builder-history&page=open-bets",
          }}
        >
          {t("admin.Openbets")}
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <Link
          className={`hdr-navigation-link-bc ${page === "won" ? "active" : ""}`}
          to={{
            search: "profile=open&account=bet-builder-history&page=won",
          }}
        >
          {t("admin.Won")}
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <Link
          className={`hdr-navigation-link-bc ${
            page === "lost" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=bet-builder-history&page=lost",
          }}
        >
          {t("admin.Lost")}
          <i className="  count-blink-even " data-badge="" />
        </Link>
      </div>
    </div>
  );
};

export { BBHistoryNav };
