import React, { useEffect, useRef, useState } from "react";
import { CasinoIconGameType } from "../../common/types/Casino/CasinoIconGameType";
import { GameItem } from "../../components/casinoList/gameItem/GameItem";
import { Layout } from "../../components/layout";
import { CasinoInfo } from "../../services";

import "./Games.scss";
import { useTypeSelector } from "hooks/useTypeSelector";

const Games = () => {
 const [loadingAdd, setLoadingAdd] = useState(true);
 const [casinoGames, setCasinoGames] = useState<CasinoIconGameType[]>([]);
 const [checkedGame, setCheckedGame] = useState(-1);
 const handleCheckItem = (item: number) => {
  setCheckedGame(item);
 };
 const wrapperRef = useRef(null);
 const handleTapOutside = (event: MouseEvent | TouchEvent) => {
  if (
   wrapperRef.current &&
   !(wrapperRef.current as HTMLElement).contains(event.target as Node)
  ) {
   setCheckedGame(-1);
  }
 };

 useEffect(() => {
  document.addEventListener("mousedown", handleTapOutside);
  document.addEventListener("touchstart", handleTapOutside);
  return () => {
   document.removeEventListener("mousedown", handleTapOutside);
   document.removeEventListener("touchstart", handleTapOutside);
  };
 }, []);
 useEffect(() => {
  async function casinoGames() {
   const res = await CasinoInfo.games(
    `/6?current_page=1&limit=25&page=1&is_mobile=true`
   );
   setCasinoGames(res.data.data);
   setLoadingAdd(false);
  }
  casinoGames();
 }, []);
 const { showRedirectInfo } = useTypeSelector((data) => data.settingsReducer);
 return (
  <Layout
   containerStyles="is-home-page skill-games-page games-custom-container"
   navHeader="games"
   footerNav="games"
  >
   <div
    className="layout-content-holder-bc"
    style={showRedirectInfo ? { marginTop: "60px" } : { marginTop: "0px" }}
   >
    <div
     className="hm-row-bc has-slider"
     style={{ gridTemplateColumns: "12fr" }}
    />
    <div className="skill-games-page-bc custom-skill-games-bc">
     {loadingAdd
      ? Array.from({ length: 10 }).map((_, index) => (
         <div
          key={index}
          className="casino-game-item skeleton-loader-game-cube"
         />
        ))
      : casinoGames.map((item) => {
         return (
          <GameItem
           key={item.id}
           item={item}
           checkedGame={checkedGame}
           wrapperRef={wrapperRef}
           handleCheckItem={handleCheckItem}
          />
         );
        })}
    </div>
   </div>
  </Layout>
 );
};

export { Games };
