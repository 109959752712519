import React, { useState } from "react";

import "./FilterPage.scss";

import load from "../../../../../../../accets/images/gif/loader/loader.gif";
import { t } from "i18next";

const FilterPage = () => {
  const [loading, setLoading] = useState(false);
  const [selectedPeriod, setSlectedPeriod] = useState(24);
  const [selectOpen, setSelectOpen] = useState(false);
  const handleSelectMove = () => {
    setSelectOpen((prev) => !prev);
  };
  const [inputValue, setInputValue] = useState("");
  const [inputOpen, setInputOpen] = useState(false);
  const onChangeInout = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };
  const handleOnFocus = () => {
    setInputOpen(true);
  };
  const handleOnBlur = () => {
    if (inputValue.length > 0) {
      setInputOpen(true);
    } else {
      setInputOpen(false);
    }
  };
  const handleSlectPeriod = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSlectedPeriod(Number(e.target.value));
  };

  const handleLoad = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    setSelectOpen(false);
  };
  return (
    <div
      className="u-i-e-p-p-content-bc u-i-common-content BetBuilder-history"
      data-scroll-lock-scrollable=""
    >
      <div className="componentFilterWrapper-bc">
        <div
          className={`componentFilterLabel-bc ${selectOpen ? "active" : ""}`}
          onClick={handleSelectMove}
        >
          <i className="componentFilterLabel-filter-i-bc bc-i-filter" />
          <div className="componentFilterLabel-filter-bc">
            <p className="ellipsis">{t("admin.Filter")}</p>
            <p className="componentFilterLabel-filter-description ellipsis">
              {inputValue.length > 0 ? `${inputValue}, ` : ""}{t("admin.All")},
              {selectedPeriod} {t("admin.hours")}
            </p>
          </div>
          <i className="componentFilterChevron-bc bc-i-small-arrow-down" />
        </div>
        <div className="componentFilterBody-bc">
          <div className="componentFilterElsWrapper-bc">
            <div className="filter-form-w-bc">
              <div className="u-i-p-control-item-holder-bc">
                <div
                  className={`form-control-bc default ${
                    inputOpen ? "focused" : ""
                  }`}
                >
                  <label className="form-control-label-bc inputs">
                    <input
                      type="text"
                      inputMode="decimal"
                      className="form-control-input-bc"
                      name="bet_id"
                      onChange={onChangeInout}
                      value={inputValue}
                      onFocus={handleOnFocus}
                      onBlur={handleOnBlur}
                    />
                    <i className="form-control-input-stroke-bc" />
                    <span className="form-control-title-bc ellipsis">
                    {t("admin.BetID")}
                    </span>
                  </label>
                </div>
              </div>
              <div className="u-i-p-control-item-holder-bc">
                <div className="form-control-bc select has-icon valid filled">
                  <label className="form-control-label-bc inputs">
                    <select
                      className="form-control-select-bc active"
                      name="period"
                      onChange={handleSlectPeriod}
                    >
                      <option value={24}>24 {t("admin.hours")}</option>
                      <option value={72}>72 {t("admin.hours")}</option>
                      <option value={168}>{t("admin.Birhafta")}</option>
                      <option value={720}>30 {t("admin.Days")}</option>
                      <option value="">{t("admin.Custom")}</option>
                    </select>
                    <i className="form-control-icon-bc bc-i-small-arrow-down" />
                    <i className="form-control-input-stroke-bc" />
                    <span className="form-control-title-bc ellipsis">
                    {t("admin.Period")}
                    </span>
                  </label>
                </div>
              </div>
              <div className="u-i-p-c-footer-bc">
                <button
                  className="btn a-color "
                  type="submit"
                  title="Show"
                  onClick={handleLoad}
                >
                  <span>{t("admin.Show")}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="bc-loader medium ">
          <div className="bc-loader-contain">
            <img src={load} alt="" />
          </div>
        </div>
      ) : (
        <p className="empty-b-text-v-bc">{t("admin.Nobetstoshow")}</p>
      )}
    </div>
  );
};

export { FilterPage };
