import React, { useEffect } from "react";

import "./SuccesModal.scss";

interface ISuccesModal {
  setModalSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  modalTest: string;
}

const SuccesModal = ({ setModalSuccess, modalTest }: ISuccesModal) => {
  useEffect(() => {
    setTimeout(() => {
      setModalSuccess(false);
    }, 5000);
  }, []);
  return (
    <div className="modal-container">
      <div className="modal-cart">{modalTest}</div>
    </div>
  );
};

export { SuccesModal };
