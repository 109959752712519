import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";

import "./BetHistory.scss";

interface IBetHistory {
  setSelectedGroup: React.Dispatch<React.SetStateAction<string>>;
}

const BetHistory = ({ setSelectedGroup }: IBetHistory) => {
  const handleBack = () => {
    setSelectedGroup("");
  };
  return (
    <>
      <div className="u-i-p-l-head-bc" onClick={handleBack}>
        <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-left" />
        <span className="u-i-p-l-h-title-bc ellipsis">
          {t("admin.BetHistory")}
        </span>
      </div>
      <div className="u-i-p-l-body-bc">
        <Link
          className="u-i-p-l-head-bc"
          to={{
            search: "?profile=open&account=history&page=bets",
          }}
        >
          <i className="user-nav-icon bc-i-bet-history" />
          <span className="u-i-p-l-h-title-bc ellipsis">{t("admin.All")}</span>
          <i className="  count-blink-even " data-badge="" />
          <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-right" />
        </Link>
        <Link
          className="u-i-p-l-head-bc"
          to={{
            search: "?profile=open&account=history&page=open-bets",
          }}
        >
          <i className="user-nav-icon bc-i-open-bets" />
          <span className="u-i-p-l-h-title-bc ellipsis">
            {t("admin.Openbets")}
          </span>
          <i className="  count-blink-even " data-badge="" />
          <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-right" />
        </Link>
        <Link
          className="u-i-p-l-head-bc"
          to={{
            search: "?profile=open&account=history&page=gifted",
          }}
        >
          <i className="user-nav-icon bc-i-bonus" />
          <span className="u-i-p-l-h-title-bc ellipsis">
            {t("admin.Giftedbets")}
          </span>
          <i className="  count-blink-even " data-badge="" />
          <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-right" />
        </Link>
        <Link
          className="u-i-p-l-head-bc"
          to={{
            search: "?profile=open&account=history&page=gifts",
          }}
        >
          <i className="user-nav-icon bc-i-gift-received" />
          <span className="u-i-p-l-h-title-bc ellipsis">
            {t("admin.Receivedgifts")}
          </span>
          <i className="  count-blink-even " data-badge="" />
          <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-right" />
        </Link>
      </div>
    </>
  );
};

export { BetHistory };
