import React from "react";

import "./ProductBanner.scss";
import { BananersEnum } from "common/types/Banners/BannersList";
import { useTypeSelector } from "hooks/useTypeSelector";
import { useNavigate } from "react-router-dom";

const ProductBanner = ({ bannersType }: { bannersType: BananersEnum }) => {
  const bannersList = useTypeSelector((data) =>
    data.accountInfoReducer.banners.filter((item) => item.type === bannersType)
  );
  const navigate = useNavigate();
  const { languageShort } = useTypeSelector(
    (data) => data.settingsReducer.language
  );
  const handleNavigate = (item: string | null) => {
    item && navigate(`/${languageShort}${item}`);
  };
  return (
    <div className="hm-row-bc ">
      <div className="product-banner-container-bc col-4 product-banner-without-titles">
        {bannersList.map((item) => {
          return (
            <div
              onClick={() => handleNavigate(item.url)}
              className="product-banner-info-bc product-banner-bc"
              key={item.id}
            >
              <img
                className="product-banner-img-bc"
                src={item.mobile_image}
                alt=""
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { ProductBanner };
