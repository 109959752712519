import React, { useEffect, useRef } from "react";
import { useTypeSelector } from "../../../hooks/useTypeSelector";
import { SmartPaneAuth } from "./smartPaneAuth/SmartPaneAuth";
import "./SmartPanel.scss";
import { SmartPanelUnauth } from "./smartPanelUnauth/SmartPanelUnauth";

interface ISmartPanel {
  setSmartPanelVisibale: React.Dispatch<React.SetStateAction<boolean>>;
  setSettingsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setNotificationOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setFavoritsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setbetSlipHidden: React.Dispatch<React.SetStateAction<boolean>>;
}

const SmartPanel = ({
  setSmartPanelVisibale,
  setSettingsOpen,
  setNotificationOpen,
  setFavoritsOpen,
  setbetSlipHidden,
}: ISmartPanel) => {
  const role = useTypeSelector((data) => data.accountInfoReducer.role);
  const wrapperRef = useRef(null);
  const handleTapOutside = (event: MouseEvent | TouchEvent) => {
    if (
      wrapperRef.current &&
      !(wrapperRef.current as HTMLElement).contains(event.target as Node)
    ) {
      setSmartPanelVisibale(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleTapOutside);
    document.addEventListener("touchstart", handleTapOutside);
    return () => {
      document.removeEventListener("mousedown", handleTapOutside);
      document.removeEventListener("touchstart", handleTapOutside);
    };
  }, []);
  return (
    <div className="hdr-smart-panel-holder-arrow-bc" ref={wrapperRef}>
      {role === "player" ? (
        <SmartPaneAuth
          setSettingsOpen={setSettingsOpen}
          setSmartPanelVisibale={setSmartPanelVisibale}
          setNotificationOpen={setNotificationOpen}
          setFavoritsOpen={setFavoritsOpen}
          setbetSlipHidden={setbetSlipHidden}
        />
      ) : (
        <SmartPanelUnauth
          setSettingsOpen={setSettingsOpen}
          setSmartPanelVisibale={setSmartPanelVisibale}
          setNotificationOpen={setNotificationOpen}
          setFavoritsOpen={setFavoritsOpen}
          setbetSlipHidden={setbetSlipHidden}
        />
      )}
    </div>
  );
};

export { SmartPanel };
