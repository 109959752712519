import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Carusel.scss";
import "swiper/css";
import { CaruselCart } from "./caruselCart/сaruselCart";
import { BananersEnum, BannersList } from "common/types/Banners/BannersList";

interface ICarusel {
 mockCarusel: BannersList[];
 filtredSelect: BananersEnum;
}

const Carusel = ({ mockCarusel, filtredSelect }: ICarusel) => {
 const [indexCarusel, setIndexCarusel] = useState(1);
 const bannersList = mockCarusel.filter((item) => item.type === filtredSelect);
 return (
  <div
   className={`hm-row-bc has-slider custom-slider-container `}
   style={{ gridTemplateColumns: "12fr" }}
  >
   <div className="slider-bc  ">
    <div className="carousel">
     <div className="carousel-count custom-counter">
      <div className="carousel-color-text">{indexCarusel}</div>
      <div className="carousel-color-text">/</div>{" "}
      <div className="carousel-color-text">{bannersList.length}</div>
     </div>
     <div className="carousel-viewport">
      <Swiper
       className="carousel-container"
       spaceBetween={50}
       slidesPerView={1}
       onSlideChange={(e) => setIndexCarusel(e.activeIndex + 1)}
      >
       {bannersList.map((item) => {
        return (
         <SwiperSlide key={item.mobile_image}>
          <CaruselCart cartCaruselInfo={item} key={item.id} />
         </SwiperSlide>
        );
       })}
      </Swiper>
     </div>
    </div>
   </div>
  </div>
 );
};

export { Carusel };
