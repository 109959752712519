import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { MainItems } from "./components/MainItems/MainItems";
import { RightItems } from "./components/RightItems/RightItems";

import "./MenuNavigation.scss";

interface IMenuNavigation {
  setMenuNavigationVisibaly: React.Dispatch<React.SetStateAction<boolean>>;
}

const MenuNavigation = ({ setMenuNavigationVisibaly }: IMenuNavigation) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const handleCloseNav = () => {
    setMenuNavigationVisibaly(false);
  };
  const wrapperRef = useRef(null);
  const handleTapOutside = (event: MouseEvent | TouchEvent) => {
    if (
      wrapperRef.current &&
      !(wrapperRef.current as HTMLElement).contains(event.target as Node)
    ) {
      setMenuNavigationVisibaly(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleTapOutside);
    document.addEventListener("touchstart", handleTapOutside);
    return () => {
      document.removeEventListener("mousedown", handleTapOutside);
      document.removeEventListener("touchstart", handleTapOutside);
    };
  }, []);
  return (
    <div className="hdr-nav-menu-holder-bc">
      <div className="m-navigation-container-bc active" ref={wrapperRef}>
        <div className="m-nav-title-row-bc">
          <h3 className="m-nav-title-content-bc">{t("admin.Menu")}</h3>
          <div className="closed-n-p-bc" onClick={handleCloseNav}>
            <i className="bc-i-close-remove" />
          </div>
        </div>
        <div
          className="m-nav-info-w-container-bc"
          data-scroll-lock-scrollable=""
        >
          <div
            className={`${
              sidebarOpen
                ? "m-nav-sab-container-bc"
                : "m-nav-collapsed-container-bc"
            }`}
          >
            <MainItems setSidebarOpen={setSidebarOpen} />
            {sidebarOpen ? (
              <RightItems setSidebarOpen={setSidebarOpen} />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export { MenuNavigation };
