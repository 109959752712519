import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { login } from "../../../../../common/types/Auth/login";
import { AuthService, AdminPanel } from "../../../../../services";
// import loadGif from "../../../../../../../accets/images/gif/loader/loader.gif";
import loadGif from "../../../../../accets/images/gif/loader/loader.gif";

import "./AuthForm.scss";
import { PasswordInput } from "./Inputs/PasswordInput/PasswordInput";
import { UsernameInput } from "./Inputs/UsernameInput/UsernameInput";
import { t } from "i18next";
import { setAuth, setUserInfo, setUserBalance, setUserBalanceInfo } from "store/reducers/accountInfoReducer";

interface IAuthForm {
  handleClosePopap: () => void;
}

const AuthForm = ({ handleClosePopap }: IAuthForm) => {
  const dispatch = useDispatch();
  const [formPort, setFormPort] = useState(false);
  const [invalidData, setInvalidData] = useState(false);
  const { register, handleSubmit, watch, setValue } = useForm<login>();
  const onSubmit = (data: login) => {
    async function autotizationLogin() {
      try {
        setFormPort(true);
        const res = await AuthService.login({
          password: data.password,
          username: data.username,
        });
        if (res.status === 200) {
          dispatch(setAuth("player"));
          localStorage.setItem("token", res.data);
          const data = await AdminPanel.userInfo();
          dispatch(setUserInfo(data.data));
          handleClosePopap();
          const resdata = await AdminPanel.userBalance();
          dispatch(setUserBalanceInfo(resdata.data));
        }
        setFormPort(false);
      } catch {
        setFormPort(false);
        setInvalidData(true);
      }
    }
    autotizationLogin();
  };
  return (
    <form
      className="entrance-form-bc login popup"
      data-scroll-lock-scrollable=""
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="form-sign-bc">
        <div className="sg-n-text-row-1-bc">
          {t("admin.Alreadyhaveaccount")}?
        </div>
        <div className="sg-n-text-row-2-bc">
          {t("admin.Signinwearewaitingforyou")}
        </div>
        <UsernameInput register={register} watch={watch} />
        <PasswordInput register={register} watch={watch} setValue={setValue} />
        <div className="entrance-f-item-bc entrance-f-item-checkbox-bc">
          <div className="checkbox-control-bc">
            <label className="checkbox-control-content-bc">
              <input
                type="checkbox"
                className="checkbox-control-input-bc"
                name="remember_me"
                step={0}
              />
              <i className="checkbox-control-icon-bc bc-i-checked" />
              <p className="checkbox-control-text-bc">
                {t("admin.Rememberme")}
              </p>
            </label>
          </div>
        </div>
        {invalidData ? (
          <div className="entrance-f-item-bc  ">
            <div className="entrance-f-error-message-bc">
              {t("admin.InvalidUsernameorPassword")}
            </div>
          </div>
        ) : null}

        {formPort ? (
          <div className="entrance-form-actions-holder-bc login-ext-1">
            <div className="entrance-form-action-item-bc right">
              <div className="bc-loader small ">
                <div className="bc-loader-contain">
                  <img src={loadGif} alt="" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="entrance-form-actions-holder-bc login-ext-1">
            <div className="entrance-form-action-item-bc right">
              <button className="btn a-color " type="submit" title="Sign in">
                <span>{t("admin.Signin")}</span>
              </button>
            </div>
          </div>
        )}
        <div className="sg-n-forgot-password-text">{t("admin.Forgotyourpassword")}?</div>
      </div>
      <div title="Contact support" className="live-chat-adviser-bc">
        <i className="bc-i-live-chat" />
        <span className="ellipsis">{t("admin.Contactsupport")}</span>
      </div>
    </form>
  );
};

export { AuthForm };
