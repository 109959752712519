import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CasinoIconGameType } from "../../../common/types/Casino/CasinoIconGameType";
import { CasinoInfo } from "../../../services";
import { CasinoCart } from "../casinoCart/CasinoCart";

import "./LiveCasinoSlot.scss";
import { useTypeSelector } from "hooks/useTypeSelector";

const LiveCasinoSlot = () => {
 const [casinoGames, setCasinoGames] = useState<CasinoIconGameType[]>([]);
 const [checkedGame, setCheckedGame] = useState(-1);
 const [mainGamesList, setMainGamesList] = useState<any>([]);
 useEffect(() => {
  async function getCasinoGames() {
   const res = await CasinoInfo.games(
    `/1?current_page=1&limit=4&page=1&is_mobile=true`
   );
   setCasinoGames(res.data.data);
  }
  async function getCasinoGamesMainPage() {
    const res = await CasinoInfo.mainPageGames();
    setMainGamesList(res.data);
   }
   getCasinoGamesMainPage();
  getCasinoGames();
 }, []);
 const lang = useTypeSelector(
  (data) => data.settingsReducer.language.languageShort
 );
 const allGames =
  useTypeSelector(
   (data) =>
    data.settingsReducer.content.skinStyles?.filter(
     (item: any) => item.key === "home-page-list"
    )[0].value
  ) || null;
 const uniqueArray =
  mainGamesList?.filter(
   (obj: any, index: any, self: any) =>
    index === self?.findIndex((t: any) => t?.id === obj?.id)
  ) || null;

 const chekIdList = JSON.parse(allGames)?.row_2;
 const filteredObjects =
  uniqueArray?.filter((obj: any) => chekIdList?.includes(obj.id.toString())) ||
  null;
 return (
  <div className="hm-row-bc " style={{ gridTemplateColumns: "12fr" }}>
   <div className="page-view-more">
    <h3 className="view-title-more ellipsis custom-font-size">
     {t("navHeader.LiveCasino")}
    </h3>
    <Link className="view-see-more" to={`/${lang}/live-casino/home/-1/All`}>
     {t("casino.More")}
     <i className="view-arrow-more bc-i-small-arrow-right" />
    </Link>
   </div>
   <div className="games-horiz casino-game-item-amount">
    <div
     className="games-horiz-scroll"
     data-scroll-lock-scrollable=""
     style={{ gridTemplateRows: "auto" }}
    >
     {allGames
      ? filteredObjects?.map((obj: any) => {
         return (
          <CasinoCart
           cartInfo={obj}
           key={obj.id}
           checkedGame={checkedGame}
           setCheckedGame={setCheckedGame}
          />
         );
        })
      : casinoGames?.map((item) => {
         return (
          <CasinoCart
           cartInfo={item}
           key={item.id}
           checkedGame={checkedGame}
           setCheckedGame={setCheckedGame}
          />
         );
        })}
    </div>
   </div>
  </div>
 );
};

export { LiveCasinoSlot };
