import React, { useState } from "react";
import { Provider } from "react-redux";
import "./App.scss";
import { Navigation } from "./navigation/Navigation";
import { useEffect } from "react";
import { store } from "./store";
import { AdminPanel } from "services";
import { WebsiteUnavailable } from "pages";

enum loadingStatus {
  loading = "loading",
  unworking = "unworking",
  working = "working",
}

function App() {
  const [status, setStatus] = useState(loadingStatus.loading);
  async function setSettings() {
    try {
      const { data } = await AdminPanel.getDefaultSettings();
      if (
        data
          .filter((item) => item.key === "future_domain")[0]
          .value?.includes(window.location.origin)
      ) {
        data
          .filter((item) => item.key === "future_domain")[0]
          .value?.includes(window.location.origin);
        setStatus(loadingStatus.unworking);
      } else {
        data
          .filter((item) => item.key === "future_domain")[0]
          .value?.includes(window.location.origin)
        setStatus(loadingStatus.working);
      }
    } catch (e) {
      setStatus(loadingStatus.working);
    }
  }
  useEffect(() => {
    setSettings();
  }, []);
  useEffect(() => {
    document.body.setAttribute("dir", "ltr");
  }, []);

  return (
    <>
      {status === loadingStatus.loading && <div></div>}
      {status === loadingStatus.unworking && <WebsiteUnavailable />}
      {status === loadingStatus.working && (
        <Provider store={store}>
          <Navigation />
        </Provider>
      )}
    </>
  );
}

export default App;
