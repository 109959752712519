import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import { useTypeSelector } from "../../../../../../../hooks/useTypeSelector";

import "./RightItems.scss";

interface IRightItems {
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const RightItems = ({ setSidebarOpen }: IRightItems) => {
  const handleCloseSide = () => {
    setSidebarOpen(false);
  };
  const language = useTypeSelector(
    (data) => data.settingsReducer.language.languageShort
  );
  return (
    <div
      className="m-nav-sab-container-right-bc"
      data-scroll-lock-scrollable=""
    >
      <div
        className="m-nav-sab-container-r-row-title-bc"
        onClick={handleCloseSide}
      >
        <i className="m-nav-sab-c-r-row-icon-bc bc-i-small-arrow-left" />
        <span className="m-nav-sab-container-r-row-name-title ellipsis">
          {t("admin.Virtual")}
        </span>
      </div>
      <div className="m-nav-sab-container-r-row-bc">
        <Link
          className=""
          target="_self"
          to={`/${language}/virtual-sports/betconstruct`}
        >
          <span className="m-nav-sab-container-r-row-name ellipsis">
            BetConstruct
          </span>
        </Link>
      </div>
      <div className="m-nav-sab-container-r-row-bc">
        <Link
          className=""
          target="_self"
          to={`/${language}/virtual-sports/ext/game/155000`}
        >
          <span className="m-nav-sab-container-r-row-name ellipsis">
            Edge Gaming
          </span>
        </Link>
      </div>
      <div className="m-nav-sab-container-r-row-bc">
        <Link
          className=""
          target="_self"
          to={`/${language}/virtual-sports/ext/game/4000235`}
        >
          <span className="m-nav-sab-container-r-row-name ellipsis">
            Leap Gaming
          </span>
        </Link>
      </div>
      <div className="m-nav-sab-container-r-row-bc">
        <Link
          className=""
          target="_self"
          to={`/${language}/virtual-sports/ext/game/40004001`}
        >
          <span className="m-nav-sab-container-r-row-name ellipsis">
            {t("admin.Kiron")}
          </span>
        </Link>
      </div>
    </div>
  );
};

export { RightItems };
