import React from "react";

import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.css";

import "./BirthdayInput.scss";
import { t } from "i18next";
import { DatePickerCustom } from "components/DatePickerCustom/DatePickerCustom";

interface ICustomInput {
  birthdayData: Date | null;
  setBirthdayData: React.Dispatch<React.SetStateAction<Date | null>>;
  errorsRequest: any;
}

const CustomInput = ({ value, onClick }: any) => {
  return (
    <div className="form-control-bc default has-icon focused">
      {/* <label className="form-control-label-bc inputs ">
        <input
          className="form-control-input-bc "
          placeholder=""
          defaultValue=""
          onClick={onClick}
          value={value}
          readOnly
        />
        <i className="form-control-input-stroke-bc" />
        <span className="form-control-title-bc ellipsis">
          {t("admin.Birthdate")}
        </span>
        <i className="dropdownIcon-bc bc-i-datepicker" />
      </label> */}
    </div>
  );
};

const BirthdayInput = ({
  birthdayData,
  setBirthdayData,
  errorsRequest,
}: ICustomInput) => {
  const handleDateChange = (date: Date | null) => {
    setBirthdayData(date);
  };

  return (
    <div className="entrance-f-item-bc">
      {/* <ReactDatePicker
        className="form-control-input-bc custom-datepicker"
        selected={birthdayData}
        onChange={handleDateChange}
        showYearDropdown={false}
        customInput={<CustomInput />}
      /> */}
      <DatePickerCustom
        startDate={birthdayData}
        setStartDate={setBirthdayData}
      />
      {/* <div className="react-datepicker-wrapper">
            <div className="react-datepicker__input-container">
              <div className="form-control-bc default filled "></div>
            </div>
          </div> */}
      {errorsRequest?.birthday ? (
        <div className="form-control-message-holder-bc">
          <span className="form-control-message-bc">
            {errorsRequest?.birthday ? errorsRequest?.birthday : ""}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export { BirthdayInput };
