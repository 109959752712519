import { t } from "i18next";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CaruselAmount } from "../../CaruselAmount/CaruselAmount";
import { LoyaltyPoints } from "../../LoyaltyPoints/LoyaltyPoints";
import { BalanceManagement } from "../../SelectedGroups/balanceManagement/BalanceManagement";
import { BetBuilderhistory } from "../../SelectedGroups/betBuilderhistory/BetBuilderhistory";
import { BetHistory } from "../../SelectedGroups/betHistory/BetHistory";
import { Bonuses } from "../../SelectedGroups/bonuses/Bonuses";
import { Messages } from "../../SelectedGroups/messages/Messages";
import { MyProfile } from "../../SelectedGroups/myProfile/MyProfile";
import { UnselectedGrop } from "../../SelectedGroups/unselectedGrop/UnselectedGrop";
import { UserLine } from "../../UserLine/UserLine";

import "./MainAccount.scss";
import { setAuth } from "store/reducers/accountInfoReducer";

interface IMainAccount {
  selectedGroup: string;
  setSelectedGroup: React.Dispatch<React.SetStateAction<string>>;
}

const MainAccount = ({ selectedGroup, setSelectedGroup }: IMainAccount) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleMockUnauth = () => {
    dispatch(setAuth("unauth"));
    localStorage.removeItem("token");
    navigate("", {
      replace: true,
    });
  };
  return (
    <div className="u-i-profile-page-bc">
      <CaruselAmount />
      <div className="separator-line-bc" />
      <div className="u-i-p-links-lists-holder-bc">
        {selectedGroup === "" ? (
          <UnselectedGrop setSelectedGroup={setSelectedGroup} />
        ) : null}
        {selectedGroup === "Balance Management" ? (
          <BalanceManagement setSelectedGroup={setSelectedGroup} />
        ) : null}
        {selectedGroup === "Bet Builder history" ? (
          <BetBuilderhistory setSelectedGroup={setSelectedGroup} />
        ) : null}
        {selectedGroup === "Bonuses" ? (
          <Bonuses setSelectedGroup={setSelectedGroup} />
        ) : null}
        {selectedGroup === "My Profile" ? (
          <MyProfile setSelectedGroup={setSelectedGroup} />
        ) : null}
        {selectedGroup === "Bet History" ? (
          <BetHistory setSelectedGroup={setSelectedGroup} />
        ) : null}
        {selectedGroup === "Messages" ? (
          <Messages setSelectedGroup={setSelectedGroup} />
        ) : null}
      </div>
      <div className="promoCodeWrapper-bc profile-panel-promo-code">
        <form>
          <div className="u-i-p-control-item-holder-bc">
            <div className="form-control-bc default ">
              <label className="form-control-label-bc inputs">
                <input
                  type="text"
                  className="form-control-input-bc"
                  name="promoCode"
                  step={0}
                  defaultValue=""
                />
                <i className="form-control-input-stroke-bc" />
                <span className="form-control-title-bc ellipsis">
                  {t("admin.Promocode")}
                </span>
              </label>
            </div>
          </div>
          <div className="u-i-p-c-footer-bc">
            <button
              className="btn a-color big-btn "
              type="submit"
              title="Apply"
              disabled={true}
            >
              <span>{t("admin.Apply")}</span>
            </button>
          </div>
        </form>
      </div>
      <div className="user-logout-bc">
        <button
          className="btn "
          type="button"
          title="Logout"
          onClick={() => handleMockUnauth()}
        >
          <span>{t("admin.Logout")}</span>
        </button>
      </div>
    </div>
  );
};

export { MainAccount };
