import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";

import "./MessagesNav.scss";

interface IMessagesNav {
  page: string;
}

const MessagesNav = ({ page }: IMessagesNav) => {
  return (
    <div className="hdr-navigation-scrollable-bc">
      <div
        className="hdr-navigation-scrollable-content"
        data-scroll-lock-scrollable=""
      >
        <Link
          className={`hdr-navigation-link-bc ${
            page === "inbox" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=messages&page=inbox",
          }}
        >
          {t("admin.Inbox")}
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <Link
          className={`hdr-navigation-link-bc ${
            page === "sent" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=messages&page=sent",
          }}
        >
          {t("admin.Sent")}
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <Link
          className={`hdr-navigation-link-bc ${page === "new" ? "active" : ""}`}
          to={{
            search: "profile=open&account=messages&page=new",
          }}
        >
          {t("admin.New")}
          <i className="  count-blink-even " data-badge="" />
        </Link>
      </div>
    </div>
  );
};

export { MessagesNav };
