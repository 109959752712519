enum BananersEnum {
  LiveCasino = "live-casino",
  Promotions = "promotions",
  Casino = "casino",
  Homepage = "homepage",
  MainBigList = "main-big-list",
  MainSmallList = "main-small-list",
  loging = "loging",
  FooterTopBaner = "footer-top-banner",
  FooterCenterBaner = "footer-center-banner",
  FooterBottomBaner = "footer-bottom-banner",
  FooterPaymentBanners = "footer-payment-banners",
}

type BannersList = {
  id: number;
  type: BananersEnum;
  top_winner: number;
  top_winner_type: number | null;
  title: string;
  url: string | null;
  lang: string;
  desktop_image: string;
  desktop_image_s3: string;
  mobile_image: string;
  mobile_image_s3: string;
  mobile_image_cloudflare: string | null;
  desktop_image_cloudflare: string | null;
  active: number;
  active_from: string | null;
  active_to: string | null;
  position: number;
  position_on_page: 0;
  created_at: string;
  updated_at: string;
};

export type { BannersList };
export { BananersEnum };
