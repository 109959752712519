import { t } from "i18next";
import React from "react";
import { UseFormRegister, UseFormWatch } from "react-hook-form";
import { userFormType } from "../../RegisterForm";

import "./CurrencyInput.scss";

interface ICurrencyInput {
  register: UseFormRegister<userFormType>;
}

const CurrencyInput = ({ register }: ICurrencyInput) => {
  return (
    <div className="entrance-f-item-bc">
      <div className="form-control-bc select has-icon focused filled">
        <label className="form-control-label-bc inputs">
          <select
            className="form-control-select-bc active"
            {...register("currency")}
          >
            <option value="TRY">TRY</option>
          </select>
          <i className="form-control-icon-bc bc-i-small-arrow-down" />
          <i className="form-control-input-stroke-bc" />
          <span className="form-control-title-bc ellipsis">
            {t("admin.Currency")}
          </span>
        </label>
      </div>
    </div>
  );
};

export { CurrencyInput };
