import { t } from "i18next";
import React, { useState } from "react";

import "./PromocodeInput.scss";

interface IPromocodeInput {
  promocode: string;
  setPromocode: React.Dispatch<React.SetStateAction<string>>;
}

const PromocodeInput = ({ promocode, setPromocode }: IPromocodeInput) => {
  const [inputFocus, setInputFocus] = useState(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPromocode(e.target.value);
  };

  const handleOnFocus = () => {
    setInputFocus(true);
  };

  const handleOnBlur = () => {
    if (promocode.length === 0) {
      setInputFocus(false);
    }
  };

  return (
    <div className="entrance-f-item-bc">
      <div
        className={`form-control-bc default  ${inputFocus ? "focused" : ""}`}
      >
        <label className="form-control-label-bc inputs">
          <input
            type="text"
            className="form-control-input-bc"
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            onChange={handleChange}
          />
          <i className="form-control-input-stroke-bc" />
          <span className="form-control-title-bc ellipsis">
            {t("admin.Promocode")}
          </span>
        </label>
      </div>
    </div>
  );
};

export { PromocodeInput };
