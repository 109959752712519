const countryData: any = {
  AF: "afghanistan",
  AX: "alandislands",
  AL: "albania",
  DZ: "algeria",
  AS: "americansamoa",
  AD: "andorra",
  AO: "Angola",
  AI: "anguilla",
  AQ: "Antarctica",
  AG: "antiguaandbarbuda",
  AR: "argentina",
  AM: "armenia",
  AW: "aruba",
  AU: "australia",
  AT: "austria",
  AZ: "azerbaijan",
  BS: "bahamas",
  BH: "bahrain",
  BD: "bangladesh",
  BB: "barbados",
  BY: "belarus",
  BE: "belgium",
  BZ: "belize",
  BJ: "benin",
  BM: "bermuda",
  BT: "bhutan",
  BO: "bolivia",
  BQ: "bonaire",
  BA: "bosniaandherzegovina",
  BW: "botswana",
  BV: "bouvetisland",
  BR: "brazil",
  IO: "britishindianoceanterritory",
  BN: "bruneidarussalam",
  BG: "bulgaria",
  BF: "burkinafaso",
  BI: "burundi",
  CV: "caboverde",
  KH: "cambodia",
  CM: "cameroon",
  CA: "canada",
  KY: "caymanislands",
  CF: "centralafricanrepublic",
  TD: "chad",
  CL: "chile",
  CN: "china",
  CX: "christmasisland",
  CC: "Islands",
  CO: "colombia",
  KM: "comoros",
  CG: "congobrazzaville",
  CD: "congokinshasa",
  CK: "cookislands",
  CR: "costarica",
  CI: "cotedivoire",
  HR: "croatia",
  CU: "cuba",
  CW: "curacao",
  CY: "cyprus",
  CZ: "czechrepublic",
  DK: "denmark",
  DJ: "djibouti",
  DM: "dominica",
  DO: "dominicanrepublic",
  EC: "ecuador",
  EG: "egypt",
  SV: "elsalvador",
  GQ: "equatorialguinea",
  ER: "eritrea",
  EE: "estonia",
  ET: "ethiopia",
  FK: "falklandislands",
  FO: "faroeislands",
  FJ: "fiji",
  FI: "finland",
  FR: "france",
  GF: "frenchguiana",
  PF: "frenchpolynesia",
  TF: "frenchsouthernterritories",
  GA: "gabon",
  GM: "gambia",
  GE: "georgia",
  DE: "germany",
  GH: "ghana",
  GI: "gibraltar",
  GR: "greece",
  GL: "greenland",
  GD: "grenada",
  GP: "guadeloupe",
  GU: "guam",
  GT: "guatemala",
  GG: "guernsey",
  GN: "guinea",
  GW: "guinea-bissau",
  GY: "guyana",
  HT: "haiti",
  HM: "heardislandandmcdonaldislands",
  VA: "holysee",
  HN: "honduras",
  HK: "hongkong",
  HU: "hungary",
  IS: "iceland",
  IN: "india",
  ID: "indonesia",
  IR: "iran",
  IQ: "iraq",
  IE: "ireland",
  IM: "isleofman",
  IL: "israel",
  IT: "italy",
  JM: "jamaica",
  JP: "japan",
  JE: "jersey",
  JO: "jordan",
  KZ: "kazakhstan",
  KE: "kenya",
  KI: "kiribati",
  KP: "korea,north",
  KW: "kuwait",
  KG: "kyrgyzstan",
  LA: "laos",
  LV: "latvia",
  LB: "lebanon",
  LS: "lesotho",
  LR: "liberia",
  LY: "libya",
  LI: "liechtenstein",
  LT: "lithuania",
  LU: "luxembourg",
  MO: "macao",
  MG: "madagascar",
  MW: "malawi",
  MY: "malaysia",
  MV: "maldives",
  ML: "mali",
  MT: "malta",
  MH: "marshallislands",
  MQ: "martinique",
  MR: "mauritania",
  MU: "mauritius",
  YT: "mayotte",
  MX: "mexico",
  FM: "micronesiafederatedstatesof",
  MD: "moldova",
  MC: "monaco",
  MN: "mongolia",
  ME: "montenegro",
  MS: "montserrat",
  MA: "morocco",
  MZ: "mozambique",
  MM: "myanmar",
  NA: "namibia",
  NR: "nauru",
  NP: "nepal",
  NL: "netherlands",
  NC: "newcaledonia",
  NZ: "newzealand",
  NI: "nicaragua",
  NE: "niger",
  NG: "nigeria",
  NU: "niue",
  NF: "norfolkisland",
  MP: "northernmarianaislands",
  MK: "northmacedonia",
  NO: "norway",
  OM: "oman",
  PK: "pakistan",
  PW: "palau",
  PS: "palestine",
  PA: "panama",
  PG: "papuanewguinea",
  PY: "paraguay",
  PE: "peru",
  PH: "philippines",
  PN: "pitcairn",
  PL: "poland",
  PT: "portugal",
  PR: "puertorico",
  QA: "qatar",
  RE: "reunion",
  RO: "romania",
  RU: "russia",
  RW: "rwanda",
  BL: "saintbarthelemy",
  SH: "sainthelena",
  KN: "saintkittsandnevis",
  LC: "saintlucia",
  MF: "saintmartinfrance",
  PM: "saintpierreandmiquelon",
  VC: "saintvincentandthegrenadines",
  WS: "samoa",
  SM: "sanmarino",
  ST: "saotomeandprincipe",
  SA: "saudiarabia",
  SN: "senegal",
  RS: "serbia",
  SC: "seychelles",
  SL: "sierraleone",
  SG: "singapore",
  SX: "sintmaartennetherlands",
  SK: "slovakia",
  SI: "slovenia",
  SB: "solomonislands",
  SO: "somalia",
  ZA: "southafrica",
  GS: "southgeorgiaandthesouthsandwichislands",
  SS: "southsudan",
  KR: "southkorea",
  ES: "spain",
  LK: "srilanka",
  SD: "sudan",
  SR: "suriname",
  SJ: "svalbardandjanmayen",
  SZ: "swaziland",
  SE: "sweden",
  CH: "switzerland",
  SY: "syria",
  TW: "taiwan",
  TJ: "tajikistan",
  TZ: "unitedrepublicoftanzania",
  TH: "thailand",
  TL: "timor-leste",
  TG: "togo",
  TK: "tokelau",
  TO: "tonga",
  TT: "trinidadandtobago",
  TN: "tunisia",
  TR: "turkey",
  TM: "turkmenistan",
  TC: "turksandcaicosislands",
  XC: "northerncyprus",
  TV: "tuvalu",
  UG: "uganda",
  UA: "ukraine",
  AE: "uae",
  GB: "unitedkingdom",
  US: "unitedstates",
  UY: "uruguay",
  UZ: "uzbekistan",
  VU: "vanuatu",
  VE: "venezuela",
  VN: "vietnam",
  VG: "virginislands,british",
  VI: "virginislandsus",
  WF: "wallisandfutuna",
  EH: "westernsahara",
  YE: "yemen",
  ZM: "zambia",
  ZW: "zimbabwe",
};
export { countryData };
