import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";

import "./Messages.scss";

interface IMessages {
  setSelectedGroup: React.Dispatch<React.SetStateAction<string>>;
}

const Messages = ({ setSelectedGroup }: IMessages) => {
  const handleBack = () => {
    setSelectedGroup("");
  };
  return (
    <>
      <div className="u-i-p-l-head-bc" onClick={handleBack}>
        <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-left" />
        <span className="u-i-p-l-h-title-bc ellipsis">{t("admin.Messages")}</span>
      </div>
      <div className="u-i-p-l-body-bc">
        <Link
          className="u-i-p-l-head-bc"
          to={{
            search: "?profile=open&account=messages&page=inbox",
          }}
        >
          <i className="user-nav-icon bc-i-inbox" />
          <span className="u-i-p-l-h-title-bc ellipsis">{t("admin.Inbox")}</span>
          <i className="  count-blink-even " data-badge="" />
          <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-right" />
        </Link>
        <Link
          className="u-i-p-l-head-bc"
          to={{
            search: "?profile=open&account=messages&page=sent",
          }}
        >
          <i className="user-nav-icon bc-i-sent" />
          <span className="u-i-p-l-h-title-bc ellipsis">{t("admin.Sent")}</span>
          <i className="  count-blink-even " data-badge="" />
          <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-right" />
        </Link>
        <Link
          className="u-i-p-l-head-bc"
          to={{
            search: "?profile=open&account=messages&page=new",
          }}
        >
          <i className="user-nav-icon bc-i-add-circle" />
          <span className="u-i-p-l-h-title-bc ellipsis">{t("admin.New")}</span>
          <i className="  count-blink-even " data-badge="" />
          <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-right" />
        </Link>
      </div>
    </>
  );
};

export { Messages };
