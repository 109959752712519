import React from "react";
import { CategoriesTypeRequest } from "../../../common/types/Casino/CategoriesType";
import "./CategoriesList.scss";

interface ICategoriesList {
  categoriesList: CategoriesTypeRequest | undefined;
  categoryIdSelected: number;
  setCategoryIdSelected: React.Dispatch<React.SetStateAction<number>>;
}

const CategoriesList = ({
  categoriesList,
  categoryIdSelected,
  setCategoryIdSelected,
}: ICategoriesList) => {
  const handleSelectCategory = (item: number) => {
    setCategoryIdSelected(item);
  };
  return (
    <div className="casino-category-chooser-container">
      <div
        className="horizontal-sl-list-container"
        data-scroll-lock-scrollable=""
      >
        <div className="horizontal-sl-list casino-horizontal-sl-list-bc">
          {categoriesList
            ? Object.keys(categoriesList)?.map((index) => {
                return (
                  <div
                    className={`horizontal-sl-item-bc newgames ${
                      categoryIdSelected === categoriesList[index].id
                        ? "active"
                        : ""
                    }`}
                    data-id={287}
                    title={categoriesList[index].name}
                    key={categoriesList[index].id}
                    onClick={() =>
                      handleSelectCategory(categoriesList[index].id)
                    }
                  >
                    {categoriesList[index].id === 6 ? (
                      <i className="horizontal-sl-icon-bc bc-i-default-icon bc-i-all-games" />
                    ) : null}
                    <p className="horizontal-sl-title-bc">
                      {categoriesList[index].name}
                    </p>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
};

export { CategoriesList };
