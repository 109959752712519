import React from "react";
import { Layout } from "../../components/layout";

import "./Zeplin.scss";
import { useTypeSelector } from "hooks/useTypeSelector";

const Zeplin = () => {
 const { showRedirectInfo } = useTypeSelector((data) => data.settingsReducer);
 return (
  <Layout
   containerStyles="is-home-page compact-footer hide-tab-navigation-footer-mobile "
   navHeader="zeplin"
   loadStop={false}
   footerNav="casino"
  >
   <div
    className="layout-content-holder-bc"
    style={showRedirectInfo ? { marginTop: "60px" } : { marginTop: "0px" }}
   >
    <iframe
     className="iframe-full-page"
     title="https://pi.njoybingo.com/game.do?token=bea879be51aa8c2fd8f078ac6e5778fb_97b6132496b9c78d0bdd9d3d9a9f9dc2&pn=performads&game=PRPLAY-1301&type=FREE&lang=en"
     src="https://pi.njoybingo.com/game.do?token=bea879be51aa8c2fd8f078ac6e5778fb_97b6132496b9c78d0bdd9d3d9a9f9dc2&pn=performads&game=PRPLAY-1301&type=FREE&lang=en"
     allowFullScreen={true}
     allow="autoplay; microphone *"
    />
   </div>
  </Layout>
 );
};

export { Zeplin };
