import { t } from "i18next";
import React from "react";

import "./NotificationModal.scss";

interface INotificationModal {
  setNotificationOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const NotificationModal = ({ setNotificationOpen }: INotificationModal) => {
  const handleCloseNotification = () => {
    setNotificationOpen(false);
  };
  return (
    <div
      className="overlay-sliding-wrapper-bc  "
      style={{ transform: "translateY(0px)", opacity: 1 }}
    >
      <div
        className="overlay-sliding-w-c-content-slider-bc"
        data-scroll-lock-scrollable=""
      >
        <div className="back-nav-bc" onClick={handleCloseNotification}>
          <i className="back-nav-icon-bc bc-i-round-arrow-left" />
          <span className="back-nav-title-bc ellipsis">
            {t("admin.Whatsnew")}
          </span>
        </div>
        <p className="empty-b-text-v-bc">
          {t("admin.Youhavenonewnotifications")}
        </p>
      </div>
    </div>
  );
};

export { NotificationModal };
