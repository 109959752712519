import axios from "axios";

const url = process.env.REACT_APP_API_ORIGIN_URL;
const appToken = process.env.REACT_APP_API_ORIGIN_TOKEN;

const api = axios.create({
  baseURL: url,
  headers: {
    "Content-type": "application/json",
  },
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("token") || appToken;

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

const apiTokenLess = axios.create({
  baseURL: url,
  headers: {
    "Content-type": "application/json",
  },
});

const apiUnauth = axios.create({
  baseURL: url,
  headers: {
    "Content-type": "application/json",
  },
});

apiUnauth.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${appToken}`;

  return config;
});

export { api, apiTokenLess, apiUnauth };
