import React, { useEffect, useState } from "react";
import { Layout } from "../../components/layout";
import { Carusel } from "../../components/carusel/Carusel";
import { CasinoGamesSlot } from "../../components/main/casinogamesslot/CasinoGamesSlot";
import { FuturedGamesSlot } from "../../components/main/futuredgamesslot/FuturedGamesSlot";
import { GamesSlot } from "../../components/main/gamesslot/GamesSlot";
import { JackpotSlortar } from "../../components/jackpotslortar/jackpotslortar";
import { LiveCasinoSlot } from "../../components/main/livecasinoslot/LiveCasinoSlot";
import { PromoSlortar } from "../../components/main/promoslortar/PromoSlortar";
import { mockProductBannerBig } from "./mockProductBannerBig";

import "./Main.scss";
import { ProductBanner } from "components/ProductBanner/ProductBanner";
import { useTypeSelector } from "hooks/useTypeSelector";
import { BananersEnum } from "common/types/Banners/BannersList";

const Main = () => {
 const [load, setLoad] = useState(true);
 const { showRedirectInfo } = useTypeSelector((data) => data.settingsReducer);
 useEffect(() => {
  setTimeout(() => {
   setLoad(false);
  }, 1000);
 }, []);
 const bannersList = useTypeSelector((data) => data.accountInfoReducer.banners);
 return (
  <Layout containerStyles="is-home-page " navHeader="">
   <div
    className="layout-content-holder-bc"
    style={showRedirectInfo ? { marginTop: "60px" } : { marginTop: "0px" }}
   >
    <Carusel mockCarusel={bannersList} filtredSelect={BananersEnum.Homepage} />
    <JackpotSlortar load={load} />
    <PromoSlortar />
    <ProductBanner bannersType={BananersEnum.MainBigList} />
    <CasinoGamesSlot />
    <LiveCasinoSlot />
    <GamesSlot />
   </div>
  </Layout>
 );
};

export { Main };
