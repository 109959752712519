import React, { useState } from "react";

import "./DataPickerMonthSelect.scss";

interface IDataPickerMonthSelect {
  months: string[];
  value: string;
  changeMonth: any;
}

const DataPickerMonthSelect = ({
  months,
  value,
  changeMonth,
}: IDataPickerMonthSelect) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleSelectMonth = (item: string) => {
    changeMonth(months.indexOf(item));
  };

  const handleMovalMove = () => {
    setModalOpen((prev) => !prev);
  };
  return (
    <div
      onClick={handleMovalMove}
      className="form-control-bc select s-small react-datepicker-months filled custom-month-select"
      tabIndex={0}
    >
      <div className="form-control-label-bc form-control-select-bc inputs ">
        <span className="ellipsis">{value}</span>
        <i
          className={`form-control-icon-bc bc-i-small-arrow-${
            modalOpen ? "down" : "up"
          }`}
        />
        <i className="form-control-input-stroke-bc" />
      </div>
      {modalOpen ? (
        <div className="multi-select-label-bc" data-scroll-lock-scrollable="">
          {months.map((item) => {
            return (
              <label
                className={`checkbox-control-content-bc  ${
                  value === item ? "active" : ""
                }`}
                data-option-value={item}
                key={item}
                onClick={() => handleSelectMonth(item)}
              >
                <p
                  className="checkbox-control-text-bc"
                  style={{ pointerEvents: "none" }}
                >
                  {item}
                </p>
              </label>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export { DataPickerMonthSelect };
