import React from "react";
import { Layout } from "../../components/layout";

import loadGif from "../../accets/images/gif/loader/loader.gif";

import "./Betconstruct.scss";

const Betconstruct = () => {
  return (
    <Layout
      containerStyles={
        "virtual-sports page-full-size hide-tab-navigation-footer-mobile compact-footer"
      }
      navHeader={"betconstruct"}
    >
      <div className="bc-loader medium full">
        <div className="bc-loader-contain">
          <img src={loadGif} alt="" />
        </div>
      </div>
    </Layout>
  );
};

export { Betconstruct };
