import React from "react";

import "./PromoitonsTypeCart.scss";

interface IPromoitonsTypeCart {
  typeInfo: { name: string; dataId: string };
  selectedCart: string;
  handleSelectCart: (item: string) => void;
}

const PromoitonsTypeCart = ({
  typeInfo,
  selectedCart,
  handleSelectCart,
}: IPromoitonsTypeCart) => {
  return (
    <div
      onClick={() => handleSelectCart(typeInfo.dataId)}
      className={`horizontal-sl-item-bc ${typeInfo.dataId} ${
        selectedCart === typeInfo.dataId ? "active" : ""
      } `}
      data-id={typeInfo.dataId}
      title={typeInfo.name}
    >
      <i
        className={`horizontal-sl-icon-bc bc-i-default-icon bc-i-${typeInfo.dataId}`}
      />
      <p className="horizontal-sl-title-bc">{typeInfo.name}</p>
    </div>
  );
};

export { PromoitonsTypeCart };
