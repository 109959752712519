import React from "react";
import { Layout } from "../../components/layout";

import "./LiveCalendar.scss";
import { useTypeSelector } from "hooks/useTypeSelector";

const LiveCalendar = () => {
 const { showRedirectInfo } = useTypeSelector((data) => data.settingsReducer);
 return (
  <Layout
   containerStyles="is-home-page  "
   navHeader="liveCalendar"
   footerNav="sports"
  >
   <div
    className="layout-content-holder-bc"
    style={showRedirectInfo ? { marginTop: "60px" } : { marginTop: "0px" }}
   >
    <div className="live-calendar">
     <p className="empty-b-text-v-bc">No games available</p>
    </div>
   </div>
  </Layout>
 );
};

export { LiveCalendar };
