// type modalsReducerType = {
//   modalRegisterOpen: boolean;
//   modalAuthOpen: boolean;
//   popapOpen: boolean;
// };

// const initialState: modalsReducerType = {
//   modalRegisterOpen: false,
//   modalAuthOpen: false,
//   popapOpen: false,
// };

// type authAction = {
//   type: string;
//   payload: unknown;
// };

// const modalsReducer = (state = initialState, action: authAction) => {
//   switch (action.type) {
//     case "SET_MODAL_LOGIN":
//       return { ...state, modalAuthOpen: action.payload };
//     case "SET_MODAL_REGISTER":
//       return { ...state, modalRegisterOpen: action.payload };
//     case "SET_MODAL_POPAP":
//       return { ...state, popapOpen: action.payload };
//     default:
//       return state;
//   }
// };

// export { modalsReducer };

// export type { modalsReducerType };

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type modalsReducerType = {
  modalRegisterOpen: boolean;
  modalAuthOpen: boolean;
  popapOpen: boolean;
};

const initialState: modalsReducerType = {
  modalRegisterOpen: false,
  modalAuthOpen: false,
  popapOpen: false,
};

const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    setModalLogin: (state, action: PayloadAction<boolean>) => {
      state.modalAuthOpen = action.payload;
    },
    setModalRegister: (state, action: PayloadAction<boolean>) => {
      state.modalRegisterOpen = action.payload;
    },
    setModalPopap: (state, action: PayloadAction<boolean>) => {
      state.popapOpen = action.payload;
    },
  },
});

export const { setModalLogin, setModalRegister, setModalPopap } =
  modalsSlice.actions;
export default modalsSlice;
