import React, { useEffect, useRef, useState } from "react";
import { CasinoIconGameType } from "../../common/types/Casino/CasinoIconGameType";
import { CategoriesTypeRequest } from "../../common/types/Casino/CategoriesType";
import { GameProviderRequest } from "../../common/types/Casino/GameProvider";
import { Carusel } from "../../components/carusel/Carusel";
import { CategoriesList } from "../../components/casino/categoriesList/CategoriesList";
import { SelectProvider } from "../../components/casino/selectProvider/SelectProvider";
import { SelectProviderPopap } from "../../components/casino/selectProviderPopap/SelectProviderPopap";
import { CasinoList } from "../../components/casinoList/CasinoList";
import { JackpotSlortar } from "../../components/jackpotslortar/jackpotslortar";
import { Layout } from "../../components/layout";
import { CasinoInfo } from "../../services";

import "./Casino.scss";
import { mockCouruselCasino } from "./mockCourusel/mockCouruselCasino";
import { useTypeSelector } from "hooks/useTypeSelector";
import { BananersEnum } from "common/types/Banners/BannersList";

const Casino = () => {
  const [load, setLoad] = useState(true);

  const [categoriesList, setCategoriesList] = useState<CategoriesTypeRequest>();
  const [categoryIdSelected, setCategoryIdSelected] = useState(6);
  const categoryIdSelectedRef = useRef(6);
  categoryIdSelectedRef.current = categoryIdSelected;

  const [providerPopapOpen, setProviderPopapOpen] = useState(false);
  const [providersList, setProvidersList] = useState<GameProviderRequest>();
  const [selectedProviders, setSelectedProviders] = useState<number[]>([]);
  const [selectedProvidersUrl, setSelectedProvidersUrl] = useState("");
  const [showByParams, setShowByParams] = useState(false);

  const [loadingGamesAdd, setLoadingGamesAdd] = useState(false);
  const [loadStop, setLoadStop] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(true);

  const [casinoGames, setCasinoGames] = useState<CasinoIconGameType[]>([]);
  const [checkedGame, setCheckedGame] = useState(-1);
  const page = useRef(1);

  const [seacrhGames, setSeacrhGames] = useState("");

  const bannersList = useTypeSelector(
    (data) => data.accountInfoReducer.banners
  );

  useEffect(() => {
    setTimeout(() => {
      setLoad(false);
    }, 1000);
  }, []);

  useEffect(() => {
    async function getCategories() {
      const res = await CasinoInfo.categories("?is_mobile=true");
      setCategoriesList(res.data);
    }
    getCategories();
  }, []);

  useEffect(() => {
    async function getCategories() {
      const res = await CasinoInfo.providers("?is_mobile=true");
      setProvidersList(res.data);
    }
    getCategories();
  }, []);

  useEffect(() => {
    if (showByParams) {
      let timeUrl = "";
      selectedProviders.map((item: number) => {
        timeUrl = timeUrl + item + ",";
      });
      setSelectedProvidersUrl(timeUrl);
    } else {
      setSelectedProvidersUrl("");
    }
  }, [showByParams, selectedProviders]);

  useEffect(() => {
    page.current = 1;
    setLoadStop(false);
    async function casinoGames() {
      setLoadingAdd(true);
      const res = await CasinoInfo.games(
        `/${categoryIdSelectedRef.current}?current_page=1&limit=25&page=${
          page.current
        }&is_mobile=true${
          selectedProvidersUrl.length > 0
            ? `&providers_ids[]=${selectedProvidersUrl}`
            : ""
        }`
      );
      setCasinoGames(res.data.data);
      setLoadingAdd(false);
    }
    async function casinoGamesSearch() {
      setLoadingAdd(true);
      setLoadingGamesAdd(true);
      const res = await CasinoInfo.searchGames(
        `?current_page=1&limit=25&page=${page.current}&search=${seacrhGames}&is_mobile=true${
          selectedProvidersUrl.length > 0
            ? `&providers_ids[]=${selectedProvidersUrl}`
            : ""
        }`
      );
      setCasinoGames(res.data.data);
      setLoadingGamesAdd(false);
      if (res.data.data.length === 0) {
        setLoadStop(true);
      } else {
        setLoadStop(false);
      }
      setLoadingAdd(false);
    }
    if (seacrhGames.length === 0) {
      casinoGames();
    } else {
      casinoGamesSearch();
    }
  }, [
    categoryIdSelected,
    selectedProviders,
    selectedProvidersUrl,
    seacrhGames,
  ]);

  async function loadMoreItem() {
    async function casinoGames() {
      if (!loadStop) {
        page.current++;
        setLoadingGamesAdd(true);
        const res = await CasinoInfo.games(
          `/${categoryIdSelectedRef.current}?current_page=1&limit=25&page=${
            page.current
          }&is_mobile=true${
            selectedProvidersUrl.length > 0
              ? `&providers_ids[]=${selectedProvidersUrl}`
              : ""
          }`
        );
        setCasinoGames((prevState) => prevState.concat(res.data.data));
        if (res.data.data.length === 0) {
          setLoadStop(true);
        } else {
          setLoadStop(false);
        }
        setLoadingGamesAdd(false);
        setLoadingAdd(false);
      }
    }
    async function casinoGamesSearch() {
      page.current++;
      setLoadingGamesAdd(true);
      const res = await CasinoInfo.searchGames(
        `?current_page=1&limit=25&page=${page.current}&search=${seacrhGames}&is_mobile=true${
          selectedProvidersUrl.length > 0
            ? `&providers_ids[]=${selectedProvidersUrl}`
            : ""
        }`
      );
      setCasinoGames((prevState) => prevState.concat(res.data.data));
      setLoadingGamesAdd(false);
      if (res.data.data.length === 0) {
        setLoadStop(true);
      } else {
        setLoadStop(false);
      }
      setLoadingGamesAdd(false);
      setLoadingAdd(false);
    }
    if (seacrhGames.length === 0) {
      if (!loadStop) {
        casinoGames();
      }
    } else {
      if (!loadStop) {
        casinoGamesSearch();
      }
    }
  }

  const [filteredData, setFilteredData] = useState(providersList);
  const [searchInput, setSearchInput] = useState("");

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    setSearchInput(input);

    if (input === "") {
      setFilteredData(providersList); // Если input пустой, показываем все данные
    } else {
      filterData(input);
      setSelectedProviders([]);
    }
  };

  const filterData = (searchQuery: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const filtered = Object.keys(providersList)
      .filter((provider) =>
        provider.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .reduce((filteredObj, key) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        filteredObj[key] = providersList[key];
        return filteredObj;
      }, {});
    setFilteredData(filtered);
  };

  return (
    <Layout
      containerStyles="is-home-page live-casino-games compact-footer "
      navHeader="casino"
      loadStop={loadStop}
      footerNav="casino"
      footerNavType="casino"
    >
      <div className="layout-content-holder-bc">
        {load ? null : <Carusel
          mockCarusel={bannersList}
          filtredSelect={BananersEnum.Homepage}
        />}
        <JackpotSlortar load={load} />
        <CategoriesList
          categoriesList={categoriesList}
          categoryIdSelected={categoryIdSelected}
          setCategoryIdSelected={setCategoryIdSelected}
        />
        <SelectProvider
          setProviderPopapOpen={setProviderPopapOpen}
          setShowByParams={setShowByParams}
          showByParams={showByParams}
          selectedProviders={selectedProviders}
          seacrhGames={seacrhGames}
          setSeacrhGames={setSeacrhGames}
        />
        {providerPopapOpen ? (
          <SelectProviderPopap
            setProviderPopapOpen={setProviderPopapOpen}
            providersList={providersList}
            setSelectedProviders={setSelectedProviders}
            selectedProviders={selectedProviders}
            setShowByParams={setShowByParams}
            searchInput={searchInput}
            handleSearchChange={handleSearchChange}
            filteredData={filteredData}
          />
        ) : null}
        <CasinoList
          loadingAdd={loadingAdd}
          casinoGames={casinoGames}
          checkedGame={checkedGame}
          setCheckedGame={setCheckedGame}
          loadMoreItem={loadMoreItem}
          loadingGamesAdd={loadingGamesAdd}
        />
      </div>
    </Layout>
  );
};

export { Casino };
