import { t } from "i18next";
import React from "react";

import "./RegistrationStatusSelect.scss";

const RegistrationStatusSelect = () => {
  return (
    <div className="u-i-p-control-item-holder-bc">
      <div className="form-control-bc select has-icon valid filled">
        <label className="form-control-label-bc inputs">
          <select
            className="form-control-select-bc active"
            name="registrationStatus"
          >
            <option value="Infinity">{t("tournaments.All")}</option>
            <option value={1}>{t("tournaments.RegistrationStarted")}</option>
            <option value={2}>{t("tournaments.RegistrationFinished")}</option>
          </select>
          <i className="form-control-icon-bc bc-i-small-arrow-down" />
          <i className="form-control-input-stroke-bc" />
          <span className="form-control-title-bc ellipsis">
            {t("tournaments.RegistrationStatuses")}
          </span>
        </label>
      </div>
    </div>
  );
};

export { RegistrationStatusSelect };
